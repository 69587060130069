import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Tabs,
} from "antd";
import IntlMessages from "../../components/IntlMessage";
import { useIntl } from "react-intl";
//import SupplierPrice from "./SupplierPrice";
//import ServiceGroup from "../../components/ServiceGroup";
import propertytypesApi from "../../api/propertytype";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function PropertytypesForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  //console.log("?",loading)
  const intl = useIntl();
  useEffect(() => {
    return () => {
      isMount.current = false;
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };
        //data.propertytypes = JSON.stringify(data.propertytypes);
        console.log("values", data);

        if (currentData) {
          propertytypesApi.update(currentData.id, data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch(() => setLoading(false));
        } else {
          propertytypesApi.create(data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch(() => setLoading(false));
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };

  let initialValue = {
    title: currentData && currentData.title ? currentData.title : "",
    description:
      currentData && currentData.description ? currentData.description : "",


  };

  //console.log("initialValue", initialValue);

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.propertytypes" />} key="1">
            <Form.Item
              label={<IntlMessages id="global.name" />}
              name="title"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.description" />}
              name="description"
              rules={[
                {
                  required: false,
                },
              ]}
              valuePropName="data"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                return data;
              }}
            >
              <CKEditor editor={ClassicEditor} />
            </Form.Item>

            {/* <Form.Item
                            name="supplier_id" label={<IntlMessages id="global.supplier" />} rules={[{
                                required: true,
                                message: <IntlMessages id="global.requiredfield" />
                            }]}

                        >

                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder={intl.formatMessage({ id: "global.select_supplier" })}

                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }

                            >
                                {suppliers.map((option, index) => {

                                    return (
                                        <Option
                                            key={`${option.id}_${index}`}
                                            value={option.id}
                                        >
                                            {option.name}
                                        </Option>
                                    );
                                })}
                            </Select>


                        </Form.Item> */}
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
