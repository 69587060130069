import api from '../utils/api';
class CarsApi{
    getAll = (data) => {
        return new Promise(async (resolve, reject) => {
          try {
            const res = await api
              .get("/cars", { params: data });
            resolve(res.data);
          } catch (error) {
            reject(error);
          }
        });
      };
}
const carApi = new CarsApi();
export default carApi;