import React, { Component } from 'react';
import { connect, useSelector } from 'react-redux';
import { Row, Col, Typography, Divider, Space } from 'antd';

import { ContactsTwoTone, MailOutlined, PhoneOutlined, IdcardTwoTone, MessageTwoTone, HomeTwoTone, ReconciliationTwoTone, CalendarTwoTone, DollarTwoTone } from '@ant-design/icons';
import EventCalendar from '../appointments/EventCalendar';

import { useIntl } from "react-intl";
import CardWithIcon from '../../components/CardWithIcon';

export default function Home(props) {

    const intl = useIntl();

    const authUser = useSelector(state => state.auth.authUser);


    const cards = [
        {
            icon: <ReconciliationTwoTone />,
            title: intl.formatMessage({ id: "global.orders" }),
            description: "Manage your bookings and travelers",
            link: '/app/orders'
        },
        {
            icon: <MessageTwoTone />,
            title: intl.formatMessage({ id: "global.tours" }),
            description: "Manage your products",
            link: '/app/tours'
        },
        {
            icon: <HomeTwoTone />,
            title: intl.formatMessage({ id: "global.destinations" }),
            description: "Manage the destinations",
            link: '/app/destinations'
        },
        {
            icon: <ContactsTwoTone />,
            title: intl.formatMessage({ id: "global.customers" }),
            description: "Manage your customers",
            link: '/app/customers'
        },

        {
            icon: <DollarTwoTone />,
            title: intl.formatMessage({ id: "global.statistics" }),
            description: "Track your sale indicators",
            link: '/app/earning'
        },
    ]

    return (

        <Row gutter={[16, 24]} >
            <Col span={24}>

                <Col span={18}>
                    <Typography.Title level={4}>Hi, {authUser.firstname} {authUser.lastname}</Typography.Title>
                    <div>
                        <Typography.Text type="secondary"><MailOutlined /> {authUser.email}</Typography.Text>


                    </div>
                </Col>

            </Col>


            <Col span={24}>

                <Col md={24} sm={24}>
                    <Row gutter={[16, 24]}>

                        {
                            cards.map((card, index) => (
                                <Col span={8} key={index}>
                                    <CardWithIcon
                                        link={card.link}
                                        icon={card.icon}
                                        title={card.title}
                                        description={card.description}
                                    />
                                </Col>
                            ))
                        }

                    </Row>
                </Col>
                <Col md={3} sm={0}></Col>
            </Col>
        </Row >

    )
}

