import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import propertytypesApi from "../../api/propertytype";
import IntlMessages from "../../components/IntlMessage";
import { Button, Card, Table, Modal, Row, Col, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PageTitle from "../../components/PageTitle";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
 import ActionBar from "../../components/ActionBar";
import PropertytypesForm from "./PropertytypesForm";

Proprotytypes.propTypes = {
  items: PropTypes.array,
};
Proprotytypes.defaultProps = {
  items: [],
};

function Proprotytypes(props) {
  // const isMount = useRef(false)

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [item, setItem] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [condition, setCondition] = useState({});

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    order_by: "title",
    order_dir: "ASC",
  });
  const [modal, setModal] = useState(false);

  const onChangeTable = async (
    pagination,
    filters,
    sorter,
    extra = { currentDataSource: [] }
  ) => {
    setFilter({
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order_dir: getOrder(sorter.order),
      order_by: sorter.columnKey ? `${sorter.columnKey}` : "title",
    });
  };

  const onFilter = debounce(async (value, title, type) => {
    if (type === "search") {
      setFilter((filter) => {
        return { ...filter, keyword: value };
      });
    } else {
      const filter_obj = { ...condition, [title]: value };
      let filter_name = [];
      let filter_value = [];
      for (var key in filter_obj) {
        if (filter_obj[key]) {
          filter_name.push(key);
          filter_value.push(filter_obj[key]);
        }
      }
      setFilter((filter) => {
        return {
          ...filter,
          filter_name: filter_name,
          filter_value: filter_value,
        };
      });
    }
  }, 300);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let items = await propertytypesApi.getAll(filter);

      setItems(items.data);
      setLoading(false);
      setDeleting(false);
      if (total != items.total) setTotal(items.total);
    }
    fetchData();
  }, [filter, deleting, reload]);

  const onReload = () => {
    setReload((reload) => !reload);
  };

  const onOpenModal = (record = null) => {
    setModal(true);
    setItem(record);
    console.log(record);
  };

  const onCloseModal = () => {
    setModal(false);
  };
  const intl = useIntl();

  const onRemove = (data) => {
    Modal.confirm({
      title: intl.formatMessage({ id: "global.delete_confirm" }),
      okText: intl.formatMessage({ id: "global.yes" }),
      onOk: async () => {
        await propertytypesApi.delete(data.id);
        setDeleting(true);
      },
      onCancel() { },
    });
  };
  const onChangeStatus = async (ischecked, record) => {
    console.log(ischecked);

    setItems((items) =>
      items.map((data) => {
        if (data.id === record.id)
          return {
            ...data,
            status: ischecked,
          };
        return data;
      })
    );
  };

  const getOrder = (order) => {
    if (order === "ascend") return "ASC";
    if (order === "descend") return "DESC";
    return "ASC";
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onMultiRemove = (idDelete) => {
    Modal.confirm({
      title: intl.formatMessage({ id: "global.delete_confirm" }),
      okText: intl.formatMessage({ id: "global.yes" }),
      onOk: async () => {
        await idDelete.map((index) => propertytypesApi.delete(index));
        setDeleting(true);
      },
      onCancel() { },
    });
  };

  const columns = [

    {
      title: <IntlMessages id="global.name" />,
      key: "title",
      sorter: true,

      dataIndex: "title",
      render: (text, record) => {
        return `${record.title}`;
      },
    },

    // {
    //   title: <IntlMessages id="global.description" />,
    //   key: "description",
    //   sorter: true,

    //   dataIndex: "description",
    //   render: (text, record) => {
    //     return (
    //       //<IntlMessages id={`group.${record.description}`} />;
    //       `${record.description}`
    //     );
    //   },
    // },

    // {
    //   title: <IntlMessages id="global.status" />,
    //   dataIndex: "status",
    //   width: 100,
    //   key: "status",
    //   render: (e, record) =>
    //     record ? (
    //       <Switch
    //         checkedChildren={<IntlMessages id="global.published" />}
    //         unCheckedChildren={<IntlMessages id="global.unpublished" />}
    //         defaultChecked={e}
    //         onChange={(ischecked) => onChangeStatus(ischecked, record)}
    //       />
    //     ) : null,
    // },

    {

      title: "",
      width: 100,
      align: true,
      render: (text, record) => {
        return (
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => onOpenModal(record)}
              shape="round"
              icon={<EditOutlined />}
            >
              <IntlMessages id="global.edit" />
            </Button>

            <Button
              type="primary"
              size="small"
              onClick={() => onRemove(record)}
              danger
              shape="round"
              icon={<DeleteOutlined />}
            >
              <IntlMessages id="global.delete" />
            </Button>
          </Space>
        );
      },
    },

    {
      title: <IntlMessages id="global.id_number" />,
      key: "id",
      width: 20,
      sorter: true,
      dataIndex: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
  ];

  return (
    <Card bordered={false} style={{ minHeight: "100%" }}>
      <Row justify="space-between">
        <Col>
          {" "}
          <PageTitle title={<IntlMessages id="global.propertytypes" />} />
          <Button
            type="primary"
            size="small"
            onClick={() => onMultiRemove(selectedRowKeys)}
            danger
            shape="round"
            disabled={!hasSelected}
            icon={<DeleteOutlined />}
          >
            <IntlMessages id="global.delete" />
          </Button>
        </Col>

        <Col>
          <ActionBar
            isShowAddButton={true}
            onAdd={onOpenModal}
            onRefresh = {()=>onReload()}
            isShowPublishButtons={false}
            isShowCopyButton={false}
            isShowDeleteButton={false}
          ></ActionBar>
        </Col>
      </Row>

      {/* <FilterBar

                onFilter={onFilter}
                data={[
                    {
                        name: "category",
                        data: categories,
                        placeholder: <IntlMessages id="global.select_category" />,
                    },

                ]}

            >
            </FilterBar> */}

      <Table
        tableLayout="auto"
        columns={columns}
        dataSource={items}
        onChange={onChangeTable}
        rowKey="id"
        rowSelection={rowSelection}
        size="small"
        loading={loading}
        scroll={{
          x: "max-content",
        }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"],
          total: total,
          current: filter.page,
          pageSize: filter.limit,
        }}
      />
      {modal ? (
        <PropertytypesForm
          open={modal}
          onClose={onCloseModal}
          currentData={item}
          onReload={onReload}
        />
      ) : null}
    </Card>
  );
}

export default Proprotytypes;
