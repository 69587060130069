import React, { Component, useState } from "react";
import { connect, useDispatch } from "react-redux";

import { Button, Divider, Modal, Input, Col, Row, Space } from "antd";

import PropTypes from "prop-types";

import { publish, unpublish } from "../../redux/actions/CommonActions";
import IntlMessages from "../IntlMessage";
import { injectIntl, useIntl } from "react-intl";
const confirm = Modal.confirm;

ActionBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  textSearch: PropTypes.bool,
  onFilter: PropTypes.func,
  searchOpition: PropTypes.object,
  showFilter: PropTypes.bool,
  justify: PropTypes.oneOf(["start", "end", "center"]),
  showActionBar: PropTypes.bool,
};
ActionBar.defaultProps = {
  data: [],
  onFilter: () => { },
  textSearch: true,
  searchOpition: {},
  isShowPublishButtons: true,
  isShowDeleteButton: true,
  isShowRefreshButton: true,
  isShowAddButton: true,
  isShowCopyButton: false,
  showFilter: true,
  justify: "end",
  showActionBar: true,
};
function ActionBar(props) {
  //   const { publish, unpublish, onRefresh, rows, table } = props;
  const {
    isShowPublishButtons,
    isShowDeleteButton,
    showActionBar,
    isShowAddButton,
    isShowRefreshButton,
    onAdd,
    rows,
    table,
    onDelete,
    onRefresh,
    isDisabled,
    children
  } = props;
  // const formatMessage = props.intl.formatMessage;
  const [activeFilter, setActiveFilter] = useState(false);
  const [row, setRow] = useState(0);
  const [isDisabledCopyButton, setIsDisabledCopyButton] = useState(true);
  const intl = useIntl();
  const dispatch = useDispatch();

  //   const publish = (data) => dispatch(publish(data))
  //   const unpublish = (data) =>dispatch(unpublish(data))

  const onOpenFilter = () => {
    setActiveFilter(!activeFilter);
  };

  const onPublish = () => {
    dispatch(publish({ rows: rows, table: table })).then(() => {
      onRefresh();
    });
  };
  const onUnpublish = () => {
    dispatch(unpublish({ rows: rows, table: table })).then(() => {
      onRefresh();
    });
  };
  const openAlert = () => {
    confirm({
      title: intl.formatMessage({ id: "global.delete_confirm" }),
      okText: intl.formatMessage({ id: "global.yes" }),
      okType: "danger",
      onOk: () => deleted(),
      onCancel() { },
    });
  };

  const deleted = () => {
    onDelete()
  }

  const refresh = () => {
    onRefresh()
  }

  return (
    <Space >


      {isShowAddButton ? (
        <React.Fragment>
          <Button type="primary" onClick={() => onAdd()}>
            <IntlMessages id="global.add_new" />
          </Button>
        </React.Fragment>
      ) : null}
      {isShowPublishButtons ? (
        <React.Fragment>
          <Divider type="vertical" />
          <Button
            type="primary"
            onClick={() => onPublish}
            disabled={isDisabled}
          >
            <IntlMessages id="global.published" />
          </Button>
          <Divider type="vertical" />
          <Button
            type="primary"
            onClick={() => onUnpublish}
            disabled={isDisabled}
          >
            <IntlMessages id="global.unpublished" />
          </Button>
        </React.Fragment>
      ) : null}

      {isShowDeleteButton ? (
        <React.Fragment>
          <Divider type="vertical" />
          <Button
            type="danger"
            onClick={() => openAlert()}
            disabled={isDisabled}
          >
            <IntlMessages id="global.delete" />
          </Button>
        </React.Fragment>
      ) : null}

      {isShowRefreshButton ? (
        <React.Fragment>
          <Divider type="vertical" />
          <Button
            type="ghost"
            onClick={() => refresh()}

          >
            <IntlMessages id="global.refresh" />
          </Button>
        </React.Fragment>
      ) : null}
      <span className="ml-2">{children}</span>

    </Space>
  );
}
export default ActionBar;
