export default {
  "transaction.created_at": "Thời gian giao dịch",
  "transaction.amount": "Tiền giao dịch",
  "transaction.action": "Hành động",
  "global.fullname": "Họ và tên",
  "global.doctorlist": "Danh sách nhân viên",
  "global.document": "Tài liệu",
  "global.document_alert": "Không thể xem file khác định dạng ảnh. Hãy thử download",
  "global.count_order": "Số lượng đơn hàng",
  "global.avatar": "Ảnh",
  "global.address": "Địa chỉ",
  "global.earning_pending": "Đang chờ rút",
  'global.transaction_pending': "Đang chờ xác nhận",
  'global.transaction_approved': "Đã xác nhận",
  'global.transaction_declined': 'Bị từ chối',
  'global.earning_available': "Chưa rút",
  'global.earning_paid': "Đã rút",
  "global.count_image": "Số lượng ảnh",
  "global.discount": "Chiết khấu",
  "global.earned": "Thu về",
  "global.setup_calendar": "Quản lý giá",
  "global.property_title": "Tên căn hộ",
  "global.order_number": "Mã đơn hàng",
  'global.order_pending_confirm': 'Chờ xác nhận',
  "global.customer_info": "Người đặt",
  "global.order_cancel_pending": "Xử lý hủy",
  "global.detail_order": "Chi tiết đơn hàng",
  "global.detail_customer": "Thông tin người đặt",
  "global.phone": "Điện thoại",
  'global.order_reject': 'Từ chối',
  'global.order_cancel': " Đã hủy",
  'global.order_complete': "Đã hoàn thành",
  'global.order_expire': 'Hết hạn',
  'global.order_confirm': "Đã xác nhận",
  "global.total": "Tổng tiền",
  "global.depart": "Ngày đến",
  "global.return_date": "Ngày đi",
  "global.adults": "Số người",
  "global.full_name": "Họ tên",
  "global.created": "Ngày tạo",
  "global.setup": "Cài đặt",
  "global.setup_calender": "Quản lý giá",
  "global.role": "Chức vụ",
  "global.add": "Thêm mới",
  "global.edit": "Sửa",
  "global.password": "Mật khẩu",
  "global.admin": "Quản trị viên",
  "global.staff": "Nhân viên",
  "global.gender": "Giới tính",
  "global.date_of_bird": "Ngày sinh",
  "global.identity": "CMT/CCCD",
  "global.nam": "Nam",
  "global.bills": "Thu chi",
  "global.nu": "Nữ",
  "global.ngay_cap": "Ngày cấp",
  "global.noi_cap": "Nơi cấp",
  "global.so_bhyt": "Số BHYT",
  "widgets.recentNotifications": "Thông báo gần đây",
  "button.viewAll": "Xem tất cả",
  "widgets.recentMessage": "Tin nhắn gần đây",
  "global.user": "Người dùng",
  "global.status": "Trạng thái",
  "global.add_new": "Thêm mới",
  "global.published": "Hiển thị",
  "global.unpublished": "Ẩn",
  "global.delete": "Xóa",
  "global.delete_confirm": "Bạn có chắc chắn muốn xóa bản ghi này không?",
  "global.create": "Tạo",
  "global.update": "Cập nhật",
  "global.image": "Ảnh",
  "global.title": "Tiêu đề",
  "global.company": "Công ty",
  "global.id": "ID",
  "global.tabbasic": "Thông tin cơ bản",
  "global.alias": "Bí danh",
  "global.desc": "Mô tả",
  "global.cancel": "Hủy",
  "global.active": "Hiển thị",
  "global.inactive": "Ẩn",
  "global.deactivate": "Hủy kích hoạt",
  "global.submit": "Gửi",
  "global.trashed": "Đã xóa",
  "global.approved": "Phê duyệt",
  "global.from": "Điểm đi",
  "global.to": "Điểm đến",
  "global.price": "Giá",
  "global.car": "Xe hơi",
  "global.type": "Loại",
  "global.cancel_policy": "Chính sách",
  "global.instant_bookable": "Có thể đặt ngay",
  "global.yes": "Có",
  "global.no": "Không",
  "global.city": "Thành phố",
  "global.street": "Đường phố",
  "global.location": "Vị trí",
  "global.choose-location": "Chọn vị trí",
  "global.square_feet": "Diện tích",
  "global.cover_img": "Ảnh bìa",
  "global.gallery": "Ảnh chỗ nghỉ",
  "global.security_deposit": "Tiền đặt cọc",
  "global.minimum_nights": "Số đêm tối thiểu",
  "global.cleaning_fee": "Phí dọn dẹp",
  "global.maximum_nights": "Số đêm tối đa",
  "global.months": "Tháng",
  "global.ward": "Xã/Phường",
  "global.nearbyUtils": "Tiện ích xung quanh",
  "global.back": "Trở về",
  "global.next": "Tiếp theo",
  "global.required": "Bắt buộc",
  "global.default_option": "Chọn một option",
  "global.province": "Tỉnh/Thành phố",
  "global.district": "Quận/Huyện",
  "global.no_house": "Số nhà",
  "global.extra_price": "Giá tăng thêm",
  "global.accommodation": "Chỗ nghỉ",
  "global.dashboard": "Trang chủ",
  "global.reservations": "Đặt chỗ",
  "global.chat": "Tin nhắn",
  "global.conversation": "Hội thoại",
  "global.calendar": "Lịch đặt chỗ",
  "global.earning": "Doanh thu",
  "global.firstname": "Tên",
  "global.lastname": "Họ và đệm",
  "global.email": "Email",
  "global.detail_property": "Thông tin căn hộ",
  "global.mobile": "Điện thoại",
  "global.birthday": "Ngày sinh",
  "global.id_name": "Họ tên đầy đủ",
  "global.id_number": "Số CMND/Căn cước",
  "global.id_create": "Ngày cấp",
  "global.id_image": "Ảnh CMND/Căn cước",
  "global.property": "Chỗ nghỉ",
  "global.create_bank": "Thêm tài khoản ngân hàng",
  "global.name_bank": "Ngân hàng",
  "global.branch": "Chi nhánh",
  "global.account_name": "Tên tài khoản",
  "global.account_number": "Số tài khoản",
  "global.notes": "Ghi chú",
  "global.note": "Ghi chú",
  "global.note_by": "Ghi chú bởi",
  "global.note_placeholder": "Thêm ghi chú...",
  "global.day": "Ngày",
  "global.last_30_day": "30 ngày gần nhất",
  "global.last_7_day": "7 ngày gần nhất",
  "global.last_month": "Tháng trước",
  "global.this_month": "Tháng này",

  "global.service_orgin" : "Xuất xứ",
  "global.service_clientCode" : "Mã Khách Hàng",







  "global.action": "Hành động",
  "global.preview": "Xem trước",
  "global.selected_items": "Đang chọn {count} bản ghi",
  "global.ownership_document": "Tài liệu",

  "sidebar.amenity": "Lễ nghi",
  "sidebar.bills": "Thu chi",
  "sidebar.services": "Dịch vụ",

  "sidebar.doctors": "Nhân viên",
  "sidebar.inbox": "Tin nhắn",
  "sidebar.conversation": "Cuộc hội thoại",
  "sidebar.reservations": "Đặt chỗ",
  "sidebar.earning": "Doanh thu",
  "sidebar.calendar": "Lịch",
  "sidebar.promotion": "Khuyến mại",
  "sidebar.promotionDetail": "Chi tiết khuyến mại",
  "sidebar.reports": "Báo cáo",
  "sidebar.orders": "Đặt Lab",


  "global.working_day_price": "Trong tuần",
  "global.weekend_price": "Cuối tuần",
  "global.description": "Mô tả",
  "global.save": "Lưu",
  "global.actions": "Hành động",

  "doctor.list": "Danh sách bác sĩ và nhân viên",
  "promotion.start_date": "Áp dụng từ",
  "promotion.end_date": "Đến",
  "promotion.start_buy_date": "Khi mua từ",
  "promotion.end_buy_date": "Đến",
  "promotion.style": "Kiểu",
  "promotion.amount": "Giá trị",
  "promotion.information": "Thông tin chung",
  "promotion.items": "Danh sách items",
  "promotion.choose_product": "Chọn loại",
  "promotion.choose_item": "Chọn item",
  "global.transport": "Di chuyển",
  "promotion.percent": "Phần trăm",
  "promotion.fixed_number": "Cố định",
  "promotion.normal": "Thông thường",
  "promotion.addition_of_admin": "Thêm bởi admin",
  "promotion.edit": "Sửa khuyến mãi",
  "promotion.create": "Thêm khuyến mãi",
  "global.male": "Nam",
  "global.female": "Nữ",
  "global.date": "Ngày",
  "global.hour": "Giờ",
  "global.free": "Miễn phí",
  "global.detail": "Chi tiết",
  "route.duration": "Thời gian dự kiến",
  "route.free_waiting_time": "Thời gian miễn phí chờ",
  "route.cancel_hour_policy": "Huỷ miễn phí nếu huỷ ... trước giờ đón",
  "global.route_add": "Tạo tuyến đường",
  "global.customer_info": "Người đặt",
  "global.select_customer": "Chọn một công ty",
  "global.select_vehicle": "Chọn phương tiện",
  "global.input_title": "Nhập tiêu đề",
  "global.select_airport": "Chọn sân bay",
  "global.select_city": "Chọn tỉnh/thành phố",
  "global.select_district": "Chọn quận/huyện",
  "global.type_desc": "Nhập mô tả",
  "route.success": "Thành công",
  "route.create_success": "Tạo tuyến đường thành công. Bạn có muốn tiếp tục tạo tuyến đường khác?",
  "route.continue": "Tiếp tục tạo",
  "route.goto_list": "Quay lại danh sách",
  "global.route_detail": "Chi tiết tuyến đường",
  "global.min": "Phút",
  "global.terms&Conditions": "Điều khoản và điều kiện",
  "global.$": "VNĐ",
  "global.airport": "Sân bay",
  "global.patients": "Bệnh nhân",
  "global.setting": "Cài đặt",
  "global.appointments": "Lịch hẹn",
  "global.suppliers": "Nhà cung cấp",
  "global.supplier": "Nhà cung cấp",
  "global.id_number": "TT",
  "global.code": "Mã số",
  "global.clinic": "Phòng khám",
  "global.website": "Website",
  "global.contact_name": "Tên liên hệ",

  "global.doctor": "Bác sĩ",
  "global.service": "Dịch vụ",
  "global.amount": "Phí",
  "global.services": "Dịch vụ",
  "global.datetime": "Ngày giờ",
  "global.treatment": "Thủ thuật",
  "global.select_service": "Chọn dịch vụ",
  "global.select_doctor": "Chọn bác sĩ",
  "global.assistant": "Chọn trợ lý",
  "global.treated_at": "Thời gian",
  "global.warranty": "Bảo hành",
  "global.name": "Tên",
  "global.signin": "Đăng nhập",
  "global.registration_header": "Đăng ký tài khoản",
  "signup.send_activation_success": "Gửi email kích hoạt thành công",
  "signup.activation_guide": "Bạn hãy kiểm tra hòm thư hoặc trong mục thư rác của bạn và làm theo hướng dẫn để kích hoạt tài khoản",
  "signup.back_to_login": "Quay lại trang đăng nhập",
  "global.signup": "Đăng ký tài khoản",
  "global.is_warrantable": "Có bảo hành",
  "global.supplies_price": "Giá vật tư",
  "global.patients": "Bệnh nhân",
  "global.forgot_password": "Quên mật khẩu",
  "global.emai_required": "Yêu cầu điền email",
  "global.password_required": "Yêu cầu điền mật khẩu",
  "global.emai_not_valid": "Email không đúng định dạng",
  "global.confirm_password": "Xác nhận mật khẩu",
  "global.changepassword": "Đổi mật khẩu",
  "global.confirm_new_password": "Xác nhận mật khẩu mới",
  "global.new_password": "Mật khẩu mới",
  "global.current_password": "Mật khẩu hiện tại",
  "global.password_not_match": "Mật khẩu không khớp",
  "global.password_min_charactor": "Độ dài mật khẩu tối thiểu 6 ký tự",
  "global.continue": "Tiếp tục",
  "global.country": "Quốc gia",
  "global.clinic_name": "Tên phòng khám",
  "global.signout": "Thoát",
  "global.email_reset_password": "Điền email của bạn",
  "global.recover_password": "Lấy lại mật khẩu",
  "global.search_placeholder": "Gõ để tìm kiếm",
  "global.requiredfield": "Bắt buộc điền dữ liệu",
  "global.deleted_success": "Xóa dữ liệu thành công",
  "global.accept_agreement_warning": "Bạn phải đồng ý với các điều khoản để tiếp tục",
  "global.language": "Ngôn ngữ",
  "global.currency": "Tiền tệ",
  "global.save_success": "Lưu thành công",
  "global.save_failed": "Có lỗi xảy ra",
  "global.payment": "Thanh toán",
  "global.orders": "Đặt hàng",
  "global.patient": "Bệnh nhân",
  "global.timezone": "Múi giờ",
  "appointment.scheduled_date": "Thời gian hẹn",
  "appointment.create": "Tạo lịch hẹn",
  "appointment.status_confirmed": "Xác nhận",
  "appointment.status_checkedin": "Đã đến",
  "appointment.status_noshow": "Không đến",
  "appointment.status_cancelled": "Đã hủy",
  "global.payment_method": "Phương thức thanh toán",
  "global.method": "Phương thức",
  "global.cash": "Tiền mặt",
  "global.card": "Thẻ",
  "global.bank": "Chuyển khoản",
  "global.insurance": "Bảo hiểm",
  "bill.billed_date": "Ngày",
  "bill.serial": "Mã",
  "bill.amount": "Số tiền",
  "bill.total_amount": "Tổng số tiền: ",
  "bill.total_cases": "Tổng số ca: ",
  "global.data_initialization": "Chúng tôi đang khởi tạo dữ liệu cho bạn !",
  




  "global.unit": "Đơn vị",
  "unit.item": "Chiếc",
  "unit.teeth": "Răng",
  "unit.tooth": "Hàm",
  "unit.time": "Lần",
  "global.account_information": "Thông tin tài khoản",
  "global.upgrade": "Nâng cấp",
  "global.your_plan": "Loại tài khoản",
  "global.trial_end": "Ngày hết hạn",
  "global.license": "Giấy phép",
  "global.telephone": "Điện thoại",
  "clinic.alias": "Tên viết tắt",
  "global.duration": "Thời gian làm",
  "global.select_patient": "Chọn bệnh nhân",
  "global.select_supplier": "Chọn nhà cung cấp",
  "order.delivery_at": "Ngày giao",
  "order.sample_at": "Ngày nhận mẫu",
  "order.ordered_at": "Ngày đặt",
  "global.qty": "Số lượng",
  "global.total_qty": "Tổng số lượng:",
  "global.story": "Tiền sử bệnh",
  "global.field_bloodpressure": "Huyết áp",
  "global.field_bpm": "Nhịp tim",
  "global.field_spo2": "Spo2",
  "global.field_height": "Cao",
  "global.field_weight": "Cân nặng",
  "global.add_supplier": "Thêm nhà cung cấp",
  "global.paid": "Đã TT",
  "global.notpaid": "Chưa TT",
  "global.category": "Nhóm",
  "global.select_category": "Chọn nhóm",
  "group.xray": "X Quang",
  "group.whitening": "Làm trắng",
  "group.implant": "Implant",
  "group.hygiene": "Vệ sinh",
  "group.rootcanal": "Điều trị tủy",
  "group.surgery": "Phẫu thuật",
  "group.prosthetic": "Răng giả",
  "group.consultation": "Khám và tư vấn",


  "global.categories":"Thể loại",
  "global.propertytypes":"Khách sạn"













};
