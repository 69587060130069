import React, { useEffect, useRef, useState } from "react";
import { Modal, Form, Input, Button, Select, Tabs, Radio } from "antd";
import IntlMessages from "../../components/IntlMessage";
import { useIntl } from "react-intl";
import widgetsApi from "../../api/widget"
import destinationsApi from "../../api/destinations";
import tourApi from "../../api/tour";
import categoriesApi from "../../api/categories";
import _ from "lodash";
import sitesApi from "../../api/sites";

const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function WidgetForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [objects, setObjects] = useState();

  const [sites, setSites] = useState([]);
  //console.log("?",loading)
  const intl = useIntl();
  useEffect(async () => {
    isMount.current = true;
    if (isMount) {
      var filter = {
        page: 1,
        limit: 1000,
        keyword: "",
        order_by: "id",
        order_dir: "DESC",
      };
      let sites = await sitesApi.getAll(filter);
      setSites(sites.data);
      if (currentData) {
        const items = await getContentSelect(currentData.type);

        setObjects(items);

      }
    }

    return () => {
      isMount.current = false;
    };
  }, []);

  const widgetTypes = {
    TOUR: "tour",
    DESTINATION: "destination",
    CATEGORY: "category",
    POST: "post",
    CUSTOM: "custom",
  }


  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values, content: values.content.toString() };

        console.log('data', data);
        if (currentData) {
          widgetsApi
            .update(currentData.id, data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        } else {
          widgetsApi
            .create(data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };

  const getContentSelect = async (type) => {

    var filter = {
      page: 1,
      limit: 1000,
      keyword: "",
      order_by: "id",
      order_dir: "DESC",
    };
    let items = null;
    if (type === "category") {
      items = await categoriesApi.getAll(filter);
      //setObjects(items)
    }
    if (type === "destination") {
      items = await destinationsApi.getAll(filter);
      // setObjects(items)
    }

    if (type === "tour") {
      items = await tourApi.getAll(filter);
      // setObjects(items)
    }


    return items.data;

  };

  const handleChange = async (value) => {
    const items = await getContentSelect(value);
    setObjects(items);
  }



  let initialValue = {

    title: currentData && currentData.title ? currentData.title : "",
    sku: currentData && currentData.sku ? currentData.sku : "",
    type: currentData && currentData.type ? currentData.type : "",
    content: currentData && currentData.content ? currentData.content : [],

    status: currentData && currentData.status ? true : false,
    site_id:
      currentData && currentData.site && currentData.site.id ?
        currentData.site.id
        : "",
  };

  // console.log("currenData:", currentData);

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.basic" />} key="1">

            <Form.Item
              name="site_id"
              label={<IntlMessages id="global.site" />}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a site"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {sites.map((option, index) => {
                  return (
                    <Option key={`${option.id}_${index}`} value={option.id}>
                      {option.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.sku" />}
              name="sku"
              rules={[
                {

                  message: <IntlMessages id="global.email_not_valid" />,
                },

                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.title" />}
              name="title"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>



            <Form.Item
              label={<IntlMessages id="global.type" />}
              name="type"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select type"
                optionFilterProp="children"
                onChange={handleChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {
                  Object.entries(widgetTypes).map(([key, value]) => (
                    <Option key={value}>{key}</Option>

                  ))};

              </Select>
            </Form.Item>

            <Form.Item
              name="content"
              label={<IntlMessages id="global.items" />}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                placeholder="Select items"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >


                {objects ? objects.map((option, index) => {
                  return (
                    <Option key={`${option.id}_${index}`} value={option.id}>
                      {option.title}
                    </Option>
                  );
                }) : ""}
              </Select>
            </Form.Item>





            <Form.Item
              name="status"
              label={<IntlMessages id="global.status" />}
            >
              <Radio.Group>
                <Radio value={false}>
                  <IntlMessages id="global.inactive" />
                </Radio>
                <Radio value={true}>
                  <IntlMessages id="global.active" />
                </Radio>
              </Radio.Group>
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
