import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Tabs,
  Row,
  Col,
  Radio,
} from "antd";
import IntlMessages from "../../components/IntlMessage";
import { useIntl } from "react-intl";
import usersApi from "../../api/users";

import config from "../../config";
import UploadImage from "../../components/Elements/UploadImage";


const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function UsersForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const place_pass = "input password";
  //console.log("?",loading)
  const intl = useIntl();
  useEffect(() => {
    return () => {
      isMount.current = false;
    };
  }, []);

  const rolesTypes = {
    Super: "super",
    Admin: "admin",
    Driver: "driver",
    Customer: "customer",
    Agent: "agent",
  };

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };
        //data.propertytypes = JSON.stringify(data.propertytypes);
        console.log("values", data);
        delete data.image;
        const formData = new FormData();
        Object.keys(data).forEach((key) => {

          formData.append(key, data[key]);
        });
        if (imgs) {
          formData.append("image", imgs);
        }

        if (currentData) {
          usersApi
            .update(currentData.id, data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false)
            });
        } else {
          usersApi
            .create(data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };

  let initialValue = {

    firstname: currentData && currentData.firstname ? currentData.firstname : "",
    lastname: currentData && currentData.lastname ? currentData.lastname : "",
    email: currentData && currentData.email ? currentData.email : "",
    roles: currentData && currentData.roles ? currentData.roles : "",
    mobile: currentData && currentData.mobile ? currentData.mobile : "",
   
    is_verified: currentData && currentData.is_verified ? 1 : 0,
    is_mobile_verified: currentData && currentData.is_mobile_verified ? 1 : 0,
    status: currentData && currentData.status ? 1 : 0,

  };
  const fileList = useMemo(
    () =>
      currentData && currentData.image
        ? [
          {
            uid: "1",
            name: "image.png",
            status: "done ",
            url: `${config.MEDIA_URL}${currentData.image}`,
          },
        ]
        : [],
    [currentData]
  );

  //console.log("initialValue", initialValue);
  const [imgs, setImgs] = useState(null);
  const onChangeImage = (v, f) => {
    setImgs(f);
  };

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.users" />} key="1">


            <Form.Item
              label={<IntlMessages id="global.lastname" />}
              name="lastname"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.firstname" />}
              name="firstname"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.roles" />}
              name="roles"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {
                  Object.entries(rolesTypes).map(([key, value]) => (
                    <Option key={value}>{key}</Option>

                  ))};

              </Select>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.email" />}
              name="email"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

          
            <Form.Item
              label={<IntlMessages id="global.phone" />}
              name="mobile"
              rules={[
                {
                  required: false,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.is_verified_email" />}
              name="is_verified"
            >
              <Radio.Group >
                <Radio value={0}>
                  <IntlMessages id="global.unverified" />
                </Radio>
                <Radio value={1}>
                  <IntlMessages id="global.verified" />
                </Radio>
              </Radio.Group>
            </Form.Item>

            {/* <Form.Item
              label={<IntlMessages id="global.image" />}
              name="image"

            >
              <UploadImage onChangeData={onChangeImage} initList={fileList} />
            </Form.Item> */}

            <Form.Item
              label={<IntlMessages id="global.is_mobile_verified" />}
              name="is_mobile_verified"
            >
              <Radio.Group >
                <Radio value={0}>
                  <IntlMessages id="global.unverified" />
                </Radio>
                <Radio value={1}>
                  <IntlMessages id="global.verified" />
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="status"
              label={<IntlMessages id="global.status" />}
            >
              <Radio.Group >
                <Radio value={0}>
                  <IntlMessages id="global.inactive" />
                </Radio>
                <Radio value={1}>
                  <IntlMessages id="global.active" />
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.password" />}
              name="password"              
            >
              <Input.Password placeholder={place_pass} />
            </Form.Item>
           

          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
