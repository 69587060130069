import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
//import propertytypesApi from "../../api/propertytype";
import IntlMessages from "../../components/IntlMessage";
import { Button, Card, Table, Modal, Row, Col } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PageTitle from "../../components/PageTitle";

import { debounce } from "lodash";
import { useIntl } from "react-intl";
import ActionBar from "../../components/ActionBar";
import FilterBar from "../../components/FilterBar";
import WishlistForm from "./WishlistForm";
import wishlisApi from "../../api/wishlist";
import tourApi from "../../api/tour";
import customersApi from "../../api/customers";

Wishlist.propTypes = {
  items: PropTypes.array,
};
Wishlist.defaultProps = {
  items: [],
};

function Wishlist(props) {


  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [item, setItem] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [condition, setCondition] = useState({});
  const [tour,setTour] = useState([])
  const [customer,setCustomer] = useState([])

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    order_by: "id",
    order_dir: "ASC",
  });
  const [modal, setModal] = useState(false);

  const onChangeTable = async (
    pagination,
    filters,
    sorter,
    extra = { currentDataSource: [] }
  ) => {
    setFilter({
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order_dir: getOrder(sorter.order),
      order_by: sorter.columnKey ? `${sorter.columnKey}` : "id",
    });
  };

  const onFilter = debounce(async (value, title, type) => {
    if (type === "search") {
      setFilter((filter) => {
        return { ...filter, keyword: value };
      });
    } else {
      const filter_obj = { ...condition, [title]: value };
      let filter_name = [];
      let filter_value = [];
      for (var key in filter_obj) {
        if (filter_obj[key]) {
          filter_name.push(key);
          filter_value.push(filter_obj[key]);
        }
      }
      setFilter((filter) => {
        return {
          ...filter,
          filter_name: filter_name,
          filter_value: filter_value,
        };
      });
    }
  }, 300);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let items = await wishlisApi.getAll(filter);

      setItems(items.data);
      setLoading(false);
      setDeleting(false);
      if (total != items.total) setTotal(items.total);
    }
    fetchData();
  }, [filter, deleting, reload]);

  useEffect(() => {
    
    async function fetchData() {
      setLoading(true);
        var filter = {
          page: 1,
          limit: 1000,
          keyword: "",
          order_by: "id",
          order_dir: "DESC",
        };
        let tour = await tourApi.getAll(filter);
        let cus = await customersApi.getAll(filter);
        setTour(tour.data);
        setCustomer(cus.data);
        setLoading(false);
    }
    fetchData();
   
  }, []);

  const onReload = () => {
    setReload((reload) => !reload);
  };

  const onOpenModal = (record = null) => {
    setModal(true);
    setItem(record);
    console.log(record);
  };

  const onCloseModal = () => {
    setModal(false);
  };
  const intl = useIntl();
  const onRemove = (data) => {
    Modal.confirm({
      title: intl.formatMessage({ id: "global.delete_confirm" }),
      okText: intl.formatMessage({ id: "global.yes" }),
      onOk: async () => {
        await wishlisApi.delete(data.id).catch((error) => {
          console.error(error);
          Modal.error({
            title: error.data.message,
            okText: intl.formatMessage({ id: "global.close" }),
          });
          setLoading(false);
        });
        setDeleting(true);
      },
      onCancel() {},
    });
  };

  const getOrder = (order) => {
    if (order === "ascend") return "ASC";
    if (order === "descend") return "DESC";
    return "ASC";
  };

  const columns = [
    {
      title: <IntlMessages id="global.id_number" />,
      key: "id",
      width: 20,
      sorter: true,
      dataIndex: "id",
      render: (index) => {
        return index + 1;
      },
    },
    {
      title: <IntlMessages id="wishlist.tour_title" />,
      key: "title",
      sorter: true,

      dataIndex: "title",
      render: (text,record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onOpenModal(record)}
            shape="round"
            icon={<EditOutlined />}
          >
            {record.tour.title}
          </Button>
        );
      },
    },

    {
      title: <IntlMessages id="wishlist.customer" />,
      key: "fullname",
      sorter: true,

      dataIndex: "fullname",
      render: (text, record) => {
        return `${record.customer.fullname}`;
      },
    },
    {
      title: <IntlMessages id="global.email" />,
      key: "email",
      sorter: true,

      dataIndex: "email",
      render: (text, record) => {
        return `${record.customer.email}`;
      },
    },

    {
      title: "",
      width: 100,
      align: true,
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onRemove(record)}
            danger
            shape="round"
            icon={<DeleteOutlined />}
          >
            <IntlMessages id="global.delete" />
          </Button>
        );
      },
    },
  ];

  return (
    <Card bordered={false} style={{ minHeight: "100%" }}>
      <Row justify="space-between">
        <Col>
          {" "}
          <PageTitle title={<IntlMessages id="global.wishlist" />} />
        </Col>

        <Col>
          <ActionBar
            isShowAddButton={true}
            onAdd={onOpenModal}
            onRefresh = {()=>onReload()}
            isShowPublishButtons={false}
            isShowCopyButton={false}
            isShowDeleteButton={false}
          ></ActionBar>
        </Col>
      </Row>

      <FilterBar

                onFilter={onFilter}
                data={[
                    {
                        name: "tour",
                        data: tour,
                        placeholder: <IntlMessages id="global.select_tour" />,
                    },
                ]}

            >
            </FilterBar>

      <Table
        tableLayout="auto"
        columns={columns}
        dataSource={items}
        onChange={onChangeTable}
        rowKey="id"
        size="small"
        loading={loading}
        scroll={{
          x: "max-content",
        }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"],
          total: total,
          current: filter.page,
          pageSize: filter.limit,
        }}
      />
      {modal ? (
        <WishlistForm
          open={modal}
          onClose={onCloseModal}
          currentData={item}
          onReload={onReload}
          customer= {customer}
          tour={tour}
        />
      ) : null}
    </Card>
  );
}

export default Wishlist;
