
import api from '../utils/api';

class SchedulesApi {
  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/schedules", { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  create = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await api
                .post("/schedules", data);

            resolve(res.data);
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });
  };

  update = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
            .patch(`/schedules/${id}`, data);
        resolve(res.data);
    } catch (error) {
        reject(error);
    }
    });
  };

  delete = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
            .delete(`/schedules/${id}`);
        resolve(res.data);
    } catch (error) {
        reject(error);
    }
});
  };

  getDetail = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/schedules/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
}
const schedulesApi = new SchedulesApi();
export default schedulesApi;