
import api from '../utils/api';

class CountriesApi {
  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/countries", { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getGroup = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/countries/group");
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  create = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .post("/countries", data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  update = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
            .patch(`/countries/${id}`, data);
        resolve(res.data);
    } catch (error) {
        reject(error);
    }
    });
  };

  delete = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
            .delete(`/countries/${id}`);
        resolve(res.data);
    } catch (error) {
        reject(error);
    }
});
  };

  getDetail = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/countries/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
}
const countriesApi = new CountriesApi();
export default countriesApi;