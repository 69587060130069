
import api from '../utils/api';

class WishlisApi {
  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/wishlists", { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
  create = (data) => {
    return new Promise((resolve, reject) => {
      return api
        .post("/wishlists", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  update = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
            .patch(`/wishlists/${id}`, data);
        resolve(res.data);
    } catch (error) {
        reject(error);
    }
    });
  };

  delete = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
            .delete(`/wishlists/${id}`);
        resolve(res.data);
    } catch (error) {
        reject(error);
    }
});
  };

  getDetail = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/wishlists/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
}
const wishlisApi = new WishlisApi();
export default wishlisApi;