import api from "../utils/api";
class ImagesApi {
  create = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post(`/images/tour/${id}`, data);

        resolve(res.data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };
  get = (id) =>{
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/images/tour/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }
}

const imagesApi = new ImagesApi();
export default imagesApi;
