import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
//import propertytypesApi from "../../api/propertytype";
import IntlMessages from "../../components/IntlMessage";
import { Button, Card, Table, Tag, Modal, Switch, Row, Col, Space } from "antd";
import TableActionBar from "../../components/FilterBar";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PageTitle from "../../components/PageTitle";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import ActionBar from "../../components/ActionBar";
import FilterBar from "../../components/FilterBar";
import CustomersForm from "./CustomersForm";
import customersApi from "../../api/customers";

Customers.propTypes = {
  items: PropTypes.array,
};
Customers.defaultProps = {
  items: [],
};

function Customers(props) {
  // const isMount = useRef(false)

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [item, setItem] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [condition, setCondition] = useState({});

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    order_by: "lastname",
    order_dir: "ASC",
  });
  const [modal, setModal] = useState(false);

  const onChangeTable = async (
    pagination,
    filters,
    sorter,
    extra = { currentDataSource: [] }
  ) => {
    setFilter({
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order_dir: getOrder(sorter.order),
      order_by: sorter.columnKey ? `${sorter.columnKey}` : "title",
    });
  };

  const onFilter = debounce(async (value, title, type) => {
    if (type === "search") {
      setFilter((filter) => {
        return { ...filter, keyword: value };
      });
    } else {
      const filter_obj = { ...condition, [title]: value };
      let filter_name = [];
      let filter_value = [];
      for (var key in filter_obj) {
        if (filter_obj[key]) {
          filter_name.push(key);
          filter_value.push(filter_obj[key]);
        }
      }
      setFilter((filter) => {
        return {
          ...filter,
          filter_name: filter_name,
          filter_value: filter_value,
        };
      });
    }
  }, 300);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let items = await customersApi.getAll(filter);

      console.log(" list customer", items.data)
      setItems(items.data);
      setLoading(false);
      setDeleting(false);
      if (total != items.total) setTotal(items.total);
    }
    fetchData();
  }, [filter, deleting, reload]);

  const onReload = () => {
    setReload((reload) => !reload);
  };

  const onOpenModal = (record = null) => {
    setModal(true);
    setItem(record);
    console.log(record);
  };

  const onCloseModal = () => {
    setModal(false);
  };
  const intl = useIntl();
  const onRemove = (data) => {
    Modal.confirm({
      title: intl.formatMessage({ id: "global.delete_confirm" }),
      okText: intl.formatMessage({ id: "global.yes" }),
      onOk: async () => {
        await customersApi.delete(data.id)
          .catch((error) => {
            console.error(error);
            Modal.error({
              title: error.data.message,
              okText: intl.formatMessage({ id: "global.close" }),
            });
            setLoading(false);
          });
        setDeleting(true);
      },
      onCancel() { },
    });
  };
  // const onChangeStatus = async (ischecked, record) => {
  //   console.log(ischecked);
  //   // await (updateService(record.id, { status: ischecked }));

  //   setItems((items) =>
  //     items.map((data) => {
  //       if (data.id === record.id)
  //         return {
  //           ...data,
  //           status: ischecked,
  //         };
  //       return data;
  //     })
  //   );
  // };

  const getOrder = (order) => {
    if (order === "ascend") return "ASC";
    if (order === "descend") return "DESC";
    return "ASC";
  };

  const columns = [
    {
      title: <IntlMessages id="global.no" />,
      width: 20,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: <IntlMessages id="global.fullname" />,
      key: "fullname",
      sorter: true,

      dataIndex: "fullname",
      render: (text, record) => {
        //return `${record.fullname}`;
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onOpenModal(record)}
            shape="round"
            icon={<EditOutlined />}
          >
            {record.fullname}
          </Button>
        );
      },
    },


    {
      title: <IntlMessages id="global.email" />,
      key: "email",
      sorter: true,

      dataIndex: "email",
      render: (text, record) => {
        return record.email;
      },
    },
    {
      title: <IntlMessages id="global.phone" />,
      key: "mobile",
      sorter: true,

      dataIndex: "mobile",
      render: (text, record) => {
        return (
          record.phone_code ?
            `+${record.phone_code}${record.mobile.slice(1)}` : record.mobile
        );
      },
    },


    {
      title: <IntlMessages id="global.userId" />,
      key: "user",
      sorter: true,

      dataIndex: "user",
      render: (text, record) => {
        return (
          record.user ?
            record.user.id : null
        );
      },
    },

    // {
    //   title: <IntlMessages id="global.phone" />,
    //   key: "phone",
    //   sorter: true,

    //   dataIndex: "phone",
    //   render: (text, record) => {
    //     return (
    //       //<IntlMessages id={`group.${record.description}`} />;
    //       `${record.phone}`
    //     );
    //   },
    // },

    // {
    //   title: <IntlMessages id="global.status" />,
    //   dataIndex: "status",
    //   width: 100,
    //   key: "status",
    //   render: (e, record) =>
    //     record ? (
    //       <Switch
    //         checkedChildren={<IntlMessages id="global.published" />}
    //         unCheckedChildren={<IntlMessages id="global.unpublished" />}
    //         defaultChecked={e}
    //         onChange={(ischecked) => onChangeStatus(ischecked, record)}
    //       />
    //     ) : null,
    // },

    {
      title: <IntlMessages id="global.id_number" />,
      key: "id",
      width: 20,
      sorter: true,
      dataIndex: "id",
      render: (text, record, index) => {
        return record.id;
      },
    },

    {
      // title: <IntlMessages id="global.mobile" />,
      title: "",
      width: 100,
      align: true,
      render: (text, record) => {
        return (
          <Space>
            {/* <Button
              type="primary"
              size="small"
              onClick={() => onOpenModal(record)}
              shape="round"
              icon={<EditOutlined />}
            >
              <IntlMessages id="global.edit" />
            </Button> */}

            <Button
              type="primary"
              size="small"
              onClick={() => onRemove(record)}
              danger
              shape="round"
              icon={<DeleteOutlined />}
            >
              <IntlMessages id="global.delete" />
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Card bordered={false} style={{ minHeight: "100%" }}>
      <Row justify="space-between">
        <Col>
          {" "}
          <PageTitle title={<IntlMessages id="global.customers" />} />
        </Col>

        <Col>
          <ActionBar
            isShowAddButton={true}
            onAdd={onOpenModal}
            onRefresh = {()=>onReload()}
            isShowPublishButtons={false}
            isShowCopyButton={false}
            isShowDeleteButton={false}
          ></ActionBar>
        </Col>
      </Row>

      {/* <FilterBar

                onFilter={onFilter}
                data={[
                    {
                        name: "category",
                        data: categories,
                        placeholder: <IntlMessages id="global.select_category" />,
                    },

                ]}

            >
            </FilterBar> */}

      <Table
        tableLayout="auto"
        columns={columns}
        dataSource={items}
        onChange={onChangeTable}
        rowKey="id"
        size="small"
        loading={loading}
        scroll={{
          x: "max-content",
        }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"],
          total: total,
          current: filter.page,
          pageSize: filter.limit,
        }}
      />
      {modal ? (
        <CustomersForm
          open={modal}
          onClose={onCloseModal}
          currentData={item}
          onReload={onReload}
        />
      ) : null}
    </Card>
  );
}

export default Customers;
