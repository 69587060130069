import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import IntlMessages from "../../components/IntlMessage";
import { Button, Card, Table, Modal, Switch, Row, Col, Space, Typography } from "antd";
import { DeleteOutlined, SettingOutlined } from "@ant-design/icons";
import PageTitle from "../../components/PageTitle";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import ActionBar from "../../components/ActionBar";
import FilterBar from "../../components/FilterBar";
import ToursForm from "./ToursForm";
import tourApi from "../../api/tour";
import categoriesApi from "../../api/categories";
import operatorsApi from "../../api/operators";
import ItineraryMGR from "./ItinerariesMGMT";
import PriceMGR from "./priceMGMT";
import config from "../../config";
import formatCurrency from "../../currencyConfigHelper";
import Gallery from "./Gallery";
import ScheduleSetting from "./ScheduleSetting";

const { Text, Link } = Typography;

Tours.propTypes = {
  items: PropTypes.array,
};

Tours.defaultProps = {
  items: [],
};

function Tours() {
  const isMount = useRef(false);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [item, setItem] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [condition, setCondition] = useState({});
  const [dataiti, setDataiti] = useState({});
  const [dataOptions, setDataOptions] = useState({});
  const [galleryData, setGalleryData] = useState({});

  //filter-category-khai bao
  const [categories, setCategories] = useState([]);
  //filter-operator-khai bao
  const [operators, setOpertors] = useState([]);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    order_by: "title",
    order_dir: "ASC",
  });
  const [modal, setModal] = useState(false);

  const onChangeTable = async (
    pagination,
    filters,
    sorter,
    extra = { currentDataSource: [] }
  ) => {
    setFilter({
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order_dir: getOrder(sorter.order),
      order_by: sorter.columnKey ? `${sorter.columnKey}` : "title",
    });
  };

  const onFilter = debounce(async (value, title, type) => {
    if (type === "search") {
      setFilter((filter) => {
        return { ...filter, keyword: value };
      });
    } else {
      const filter_obj = { ...condition, [title]: value };
      let filter_name = [];
      let filter_value = [];
      for (var key in filter_obj) {
        if (filter_obj[key]) {
          filter_name.push(key);
          filter_value.push(filter_obj[key]);
        }
      }
      setFilter((filter) => {
        return {
          ...filter,
          filter_name: filter_name,
          filter_value: filter_value,
        };
      });
    }
  }, 300);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let items = await tourApi.getAll(filter);

      setItems(items.data);
      setLoading(false);
      setDeleting(false);
      if (total != items.total) setTotal(items.total);
    }
    fetchData();
  }, [filter, deleting, reload]);
  //console.log("tour!!!!",items)
  //get list category-operator
  useEffect(() => {
    isMount.current = true;
    async function fetchData() {
      if (isMount) {
        var filter = {
          page: 1,
          limit: 1000,
          keyword: "",
          order_by: "id",
          order_dir: "DESC",
        };
        let operators = await operatorsApi.getAll(filter);
        let cat = await categoriesApi.getAll(filter);
        setOpertors(operators.data);
        setCategories(cat.data);
      }
    }
    fetchData();
    return () => {
      isMount.current = false;
    };
  }, []);

  const onReload = () => {
    setReload((reload) => !reload);
  };

  const onOpenModal = (record = null) => {
    setModal(true);
    setItem(record);
    console.log(record);
  };

  const onCloseModal = () => {
    setModal(false);
  };

  const [itinerariesForm, setItinerariesForm] = useState(false);
  const [datas, setDatas] = useState(null);

  const onOpenItineriesForm = (data = null) => {
    setDataiti(data);
    setItinerariesForm(true);
    setDatas(data);
  };

  const onCloseMGR = () => {
    setItinerariesForm(false);
  };

  const [optionForm, setOptionForm] = useState(false);
  const [dataOpt, setDataOpt] = useState(null);

  const onOpenOptions = (data = null) => {
    setDataOptions(data);
    setOptionForm(true);
    setDataOpt(data);
  };

  const onCloseOptMGR = () => {
    setOptionForm(false);
  };
  const [scheduleForm, setScheduleForm] = useState(false);
  const [dataSchedule, setDataSchedule] = useState(null);
  const onOpenSchedule = (record = null) => {
    setScheduleForm(true);
    setDataSchedule(record);
  };
  const onCloseSchedule = () => {
    setScheduleForm(false);
  };
  const [galleryForm, setGalleryForm] = useState(false);
  const [dataGallery, setDataGallery] = useState(null);
  const onOpenGallery = (data = null) => {
    setGalleryForm(true);
    setDataGallery(data);
    setGalleryData(data);
  };
  const onCloseGallery = () => {
    setGalleryForm(false);
  };

  const intl = useIntl();
  const onRemove = (data) => {
    Modal.confirm({
      title: intl.formatMessage({ id: "global.delete_confirm" }),
      okText: intl.formatMessage({ id: "global.yes" }),
      onOk: async () => {
        await tourApi.delete(data.id)
          .catch((error) => {
            console.error(error);
            Modal.error({
              title: error.data.message,
              okText: intl.formatMessage({ id: "global.close" }),
            });
            setLoading(false);
          });
        setDeleting(true);
      },
      onCancel() { },
    });
  };
  const onChangeStatus = async (ischecked, record) => {
    console.log(ischecked);
    console.log("recordStatus", record);
    await tourApi.update(record.id, {
      status: ischecked,
      destinationIds: record.destinations.map((d) => d.id),
    });
    setItems((items) =>
      items.map((data) => {
        if (data.id === record.id)
          return {
            ...data,
            status: ischecked,
          };
        return data;
      })
    );
  };

  const onChangeFeatured = async (ischecked, record) => {
    console.log(ischecked);
    console.log("recordStatus", record);
    await tourApi.update(record.id, {
      featured: ischecked,
      destinationIds: record.destinations.map((d) => d.id),
    });
    setItems((items) =>
      items.map((data) => {
        if (data.id === record.id)
          return {
            ...data,
            featured: ischecked,
          };
        return data;
      })
    );
  };

  const getOrder = (order) => {
    if (order === "ascend") return "ASC";
    if (order === "descend") return "DESC";
    return "ASC";
  };
  const columns = [
    {
      title: <IntlMessages id="global.cover_image" />,
      key: "cover_image",

      dataIndex: "cover_image",
      render: (text, record) => {
        return record.cover_image ? (
          <img
            src={`${config.MEDIA_URL}${record.cover_image}`}
            width="40"
            height="30"
            border="1px solid"
            style={{ borderRadius: "3px" }}
            alt=""
          ></img>
        ) : (
          ``
        );
      },
    },

    {
      title: <IntlMessages id="tour.title" />,
      key: "title",
      sorter: true,

      dataIndex: "title",
      render: (text, record) => {
        return (
          <>
            <Button
              type="primary"
              size="small"
              onClick={() => onOpenModal(record)}
              shape="round"
            >
              {record.title}
            </Button>
            <div>
              <Text> {record.category.title}</Text>
            </div>
          </>
        );
      },
    },

    {
      title: <IntlMessages id="global.duration" />,
      key: "duration",
      sorter: true,

      dataIndex: "duation",
      render: (text, record) => {
        return `${record.duration} ${record.duration_unit}s`;
      },
    },

    {
      title: <IntlMessages id="global.gallery" />,
      key: "gallery",
      dataIndex: "gallery",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onOpenGallery(record)}
            ghost
            shape="round"
          >
            <IntlMessages id="global.gallery" />
          </Button>
        );
      },
    },
    // {
    //   title: <IntlMessages id="global.operator" />,
    //   key: "operator",
    //   sorter: true,

    //   dataIndex: "operator",
    //   render: (text, record) => {
    //     return `${record.operator.title}`;
    //   },
    // },

    {
      title: <IntlMessages id="global.option" />,
      key: "tour_option",
      sorter: true,

      dataIndex: "tour_option",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onOpenOptions(record)}
            ghost
            shape="round"
          >
            <IntlMessages id="global.setting" />
          </Button>
        );
      },
    },

    {
      title: <IntlMessages id="global.schedule" />,
      key: "schedule",
      sorter: true,

      dataIndex: "schedule",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onOpenSchedule(record)}
            ghost
            shape="round"
          >
            {record.from_price ? (
              formatCurrency(record.from_price)
            ) : (
              <IntlMessages id="global.setting" />
            )}
          </Button>
        );
      },
    },

    {
      title: <IntlMessages id="global.ItineraryMGMT" />,
      key: "itinerary",

      dataIndex: "itinerary",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onOpenItineriesForm(record)}
            ghost
            shape="round"
            icon={<SettingOutlined />}
          >
            <IntlMessages id="global.ItineraryMGMT" />
          </Button>
        );
      },
    },

    {
      title: <IntlMessages id="tour.featured" />,
      key: "featured",
      width: 80,
      dataIndex: "featured",
      render: (text, record) =>
        record ? (
          <Switch
            checkedChildren={""}
            unCheckedChildren={""}
            checked={!!text}
            onChange={(ischecked) => onChangeFeatured(ischecked, record)}
          />
        ) : null,
    },

    // {
    //   title: <IntlMessages id="global.updated_at" />,
    //   key: "updated_at",
    //   sorter: true,

    //   dataIndex: "updated_at",
    //   render: (text, record) => {
    //     return `${record.updated_at.substring(0, 10)}`;
    //   },
    // },
    {
      title: <IntlMessages id="global.status" />,
      dataIndex: "status",
      key: "status",
      render: (e, record) =>
        record ? (
          <Switch
            checkedChildren={""}
            unCheckedChildren={""}
            checked={!!e}
            onChange={(ischecked) => onChangeStatus(ischecked, record)}
          />
        ) : null,
    },
    {
      title: <IntlMessages id="global.id_number" />,
      key: "id",
      width: 20,
      sorter: true,
      dataIndex: "id",
      render: (text, record, index) => {
        return record.id;
      },
    },

    {
      title: "",
      width: 100,
      align: true,
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onRemove(record)}
            danger
            shape="round"
            icon={<DeleteOutlined />}
          ></Button>
        );
      },
    },
  ];

  return (
    <Card bordered={false} style={{ minHeight: "100%" }}>
      <Row justify="space-between">
        <Col>
          {" "}
          <PageTitle title={<IntlMessages id="global.tour" />} />
        </Col>

        <Col>
          <ActionBar
            isShowAddButton={true}
            onAdd={onOpenModal}
            onRefresh = {()=>onReload()}
            isShowPublishButtons={false}
            isShowCopyButton={false}
            isShowDeleteButton={false}
          ></ActionBar>
        </Col>
      </Row>

      <FilterBar
        onFilter={onFilter}
        data={[
          {
            name: "category",
            data: categories,
            placeholder: <IntlMessages id="global.select_category" />,
          },
          // {
          //   name: "operator",
          //   data: operators,
          //   placeholder: <IntlMessages id="global.select_operator" />,
          // },
        ]}
      ></FilterBar>

      <Table
        tableLayout="auto"
        columns={columns}
        dataSource={items}
        onChange={onChangeTable}
        rowKey="id"
        size="small"
        loading={loading}
        scroll={{
          x: "max-content",
        }}
        sticky
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"],
          total: total,
          current: filter.page,
          pageSize: filter.limit,
        }}
      />
      {modal ? (
        <ToursForm
          open={modal}
          onClose={onCloseModal}
          currentData={item}
          onReload={onReload}
        />
      ) : null}
      {optionForm ? (
        <PriceMGR
          dataOptions={dataOptions}
          open={optionForm}
          onClose={onCloseOptMGR}
        />
      ) : null}
      {itinerariesForm ? (
        <ItineraryMGR
          dataiti={dataiti}
          open={itinerariesForm}
          onClose={onCloseMGR}
        />
      ) : null}
      {galleryForm ? (
        <Gallery
          galleryData={galleryData}
          open={galleryForm}
          onClose={onCloseGallery}
        />
      ) : null}
      {scheduleForm ? (
        <ScheduleSetting
          tourId={dataSchedule}
          open={scheduleForm}
          onClose={onCloseSchedule}
        />
      ) : null}
    </Card>
  );
}

export default Tours;
