import React, { useEffect, useRef, useState } from "react";
import { Modal, Form, Input, Button, Select, Tabs } from "antd";
import IntlMessages from "../../components/IntlMessage";
import { useIntl } from "react-intl";
import wishlisApi from "../../api/wishlist";
const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function WishlistForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open, tour, customer } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  //console.log("?",loading)
  const intl = useIntl();
  useEffect(() => {
    return () => {
      isMount.current = false;
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };
        if (currentData) {
          wishlisApi
            .update(currentData.id, data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        } else {
          wishlisApi
            .create(data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };

  let initialValue = {
    tour_id:
      currentData && currentData.tour && currentData.tour.id
        ? currentData.tour.id
        : null,
    cus_id:
      currentData && currentData.customer && currentData.customer.id
        ? currentData.customer.id
        : null,
  };

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.wishlist" />} key="1">
            <Form.Item
              label={<IntlMessages id="global.tour" />}
              name="tour_id"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Select tour"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {tour.map((option, index) => {
                  return (
                    <Option key={`${option.id}_${index}`} value={option.id}>
                      {option.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="wishlist.customer" />}
              name="cus_id"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Select customer"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {customer.map((option, index) => {
                  return (
                    <Option key={`${option.id}_${index}`} value={option.id}>
                      {`${option.firstname} ${option.lastname}`}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
