import React, { useEffect, useRef, useState } from "react";

import IntlMessages from "../../components/IntlMessage";
import { Button, Card, Table, Tag, Modal, Switch, Row, Col, Space } from "antd";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PageTitle from "../../components/PageTitle";
import { useIntl } from "react-intl";
import schedulesApi from "../../api/schedules";
import ActionBar from "../../components/ActionBar";
import formatCurrency from "../../currencyConfigHelper";
import ScheduleForm from "./ScheduleForm";
import moment from "moment";

export default function ScheduleSetting(props) {
  const isMount = useRef(false);

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [item, setItem] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const { tourId } = props;

  const days = {
    "1": "Sun",
    "2": "Mon",
    "3": "Tue",
    "4": "Wed",
    "5": "Thu",
    "6": "Fri",
    "7": "Sat",
  };

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    order_by: "id",
    order_dir: "ASC",
    filter_value: [tourId.id],
    filter_name: ["tour_id"],
  });
  const [modal, setModal] = useState(false);

  const onChangeTable = async (
    pagination,
    filters,
    sorter,
    extra = { currentDataSource: [] }
  ) => {
    setFilter({
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order_dir: getOrder(sorter.order),
      order_by: sorter.columnKey ? `${sorter.columnKey}` : "id",
    });
  };
  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      let items = await schedulesApi.getAll(filter);

      setItems(items.data);
      setLoading(false);
      setDeleting(false);
      if (total != items.total) setTotal(items.total);
    }
    fetchData();
  }, [filter, deleting, reload]);

  const onReload = () => {
    setReload((reload) => !reload);
  };

  const onOpenModal = (record = null) => {
    setModal(true);
    setItem(record);
  };

  const onCloseModal = () => {
    setModal(false);
  };
  const intl = useIntl();
  const onRemove = (data) => {
    Modal.confirm({
      title: intl.formatMessage({ id: "global.delete_confirm" }),
      okText: intl.formatMessage({ id: "global.yes" }),
      onOk: async () => {
        await schedulesApi.delete(data.id)
          .catch((error) => {
            Modal.error({
              title: error.message,
              okText: intl.formatMessage({ id: "global.close" }),
            });
            setLoading(false);
          });
        setDeleting(true);
      },
      onCancel() { },
    });
  };
  // const onChangeStatus = async (ischecked, record) => {
  //   console.log(ischecked);
  //   // await (updateService(record.id, { status: ischecked }));

  //   setItems((items) =>
  //     items.map((data) => {
  //       if (data.id === record.id)
  //         return {
  //           ...data,
  //           status: ischecked,
  //         };
  //       return data;
  //     })
  //   );
  // };

  const getOrder = (order) => {
    if (order === "ascend") return "ASC";
    if (order === "descend") return "DESC";
    return "ASC";
  };

  const columns = [

    {
      title: <IntlMessages id="global.optionTitle" />,
      key: "optionTitle",
      dataIndex: "optionTitle",

      sorter: true,
      render: (e, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onOpenModal(record)}
            shape="round"
            icon={<EditOutlined />}
          >
            {record.option.title}
          </Button>
        );
      },
    },

    {
      title: <IntlMessages id="global.adult_price" />,
      key: "adult_price",
      dataIndex: "adult_price",

      sorter: true,
      render: (e, record) => {
        return `${formatCurrency(record.adult_price)}`;
      },
    },

    {
      title: <IntlMessages id="global.child_price" />,
      key: "child_price",
      dataIndex: "child_price",

      sorter: true,
      render: (e, record) => {
        return `${formatCurrency(record.child_price)}`;
      },
    },
    {
      title: <IntlMessages id="global.start_date" />,
      key: "start_date",
      sorter: true,

      dataIndex: "start_date",
      render: (text, record) => {
        return moment(record.start_date).format("YYYY-MM-DD");
      },
    },
    {
      title: <IntlMessages id="global.end_date" />,
      key: "end_date",
      sorter: true,

      dataIndex: "end_date",
      render: (text, record) => {
        return moment(record.end_date).format("YYYY-MM-DD");
      },
    },

    {
      title: <IntlMessages id="global.weekdays" />,
      key: "weekdays",
      sorter: true,

      dataIndex: "weekdays",
      render: (text, record) => {
        let weekdays = record.weekdays.sort().map((i) => days[i] + ", ");

        return weekdays;
      },
    },
    {
      title: <IntlMessages id="global.id" />,
      key: "id",
      sorter: true,

      dataIndex: "id",
      render: (text, record) => {
        return record.id;
      },
    },

    {
      title: "",
      width: 100,
      align: true,
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onRemove(record)}
            danger
            shape="round"
            icon={<DeleteOutlined />}
          ></Button>
        );
      },
    },
  ];
  const { onClose, currentData, open } = props;
  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };
  return (
    <Modal
      title={<IntlMessages id="global.schedule_setting" />}
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      footer={null}
      width="60%"
    >
      <Card bordered={false} style={{ minHeight: "100%" }}>
        <Row justify="space-between">
          <Col>
            <h2>{<IntlMessages id="global.setting" />}</h2>
          </Col>
          <Col>
            <ActionBar
              isShowAddButton={true}
              onAdd={onOpenModal}
              onRefresh = {()=>onReload()}
              isShowPublishButtons={false}
              isShowCopyButton={false}
              isShowDeleteButton={false}
              align="right"
            ></ActionBar>
          </Col>
        </Row>

        <Table
          tableLayout="auto"
          columns={columns}
          dataSource={items}
          onChange={onChangeTable}
          rowKey="id"
          size="small"
          loading={loading}
          scroll={{
            x: "max-content",
          }}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20"],
            total: total,
            current: filter.page,
            pageSize: 5,
          }}
        />
        {modal ? (
          <ScheduleForm
            open={modal}
            onClose={onCloseModal}
            currentData={item}
            onReload={onReload}
            dataTour={tourId}
          />
        ) : null}
      </Card>
    </Modal>
  );
}
