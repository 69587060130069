import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Radio,
  Select,
  Tabs,
  TreeSelect,
} from "antd";
import IntlMessages from "../../components/IntlMessage";
import { Checkbox, Row, Col } from "antd";
import { useIntl } from "react-intl";

import UploadImage from "../../components/Elements/UploadImage";
import itinerariesApi from "../../api/itineraries";
import tourApi from "../../api/tour";
import destinationsApi from "../../api/destinations";
import config from "../../config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function ItinerariesForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open, dataTour } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tour, setTour] = useState([]);
  const [destinations, setDestinations] = useState([]);
  //console.log("?",loading)
  const intl = useIntl();
  // get list tour and destination data
  useEffect(() => {
    isMount.current = true;
    async function fetchData() {
      if (isMount) {
        var filter = {
          page: 1,
          limit: 1000,
          keyword: "",
          order_by: "id",
          order_dir: "DESC",
        };
        let tours = await tourApi.getAll(filter);
        let dest = await destinationsApi.getTree();
        setTour(tours.data);
        setDestinations(dest);
      }
    }
    fetchData();
    return () => {
      isMount.current = false;
    };
  }, []);

  const map = (e) => ({
    title: e.title,
    value: e.id,
    children: e.children.map(map), // recursive call
  }),
    treeData = destinations.map(map);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };
        console.log("111", data);
        //data.propertytypes = JSON.stringify(data.propertytypes);
        delete data.cover_image;
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        if (imgs) {
          formData.append("cover_image", imgs);
        }
        console.log("vvv", formData);

        if (currentData) {
          itinerariesApi
            .update(currentData.id, formData)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        } else {
          if (dataTour.id) {
            formData.append("tour_id", dataTour.id);
          }
          itinerariesApi
            .create(formData)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };

  let initialValue = {
    title: currentData && currentData.title ? currentData.title : "",
    description:
      currentData && currentData.description ? currentData.description : "",
    cover_image:
      currentData && currentData.cover_image ? currentData.cover_image : "",
    tour_id:
      currentData && currentData.tour && currentData.tour.id
        ? currentData.tour.id
        : "",
    dest_id:
      currentData && currentData.destination && currentData.destination.id
        ? currentData.destination.id
        : "",
    meals: currentData ? currentData.meals : [],
    ticket: currentData && currentData.ticket ? currentData.ticket : "free",
    status: currentData && currentData.status ? currentData.status : 1,
  };

  const fileList = useMemo(
    () =>
      currentData && currentData.cover_image
        ? [
          {
            uid: "1",
            name: "image.png",
            status: "done ",
            url: `${config.MEDIA_URL}${currentData.cover_image}`,
          },
        ]
        : [],
    [currentData]
  );

  console.log("initialValue", initialValue);

  const [imgs, setImgs] = useState(null);

  const onChangeImage = (v, f) => {
    setImgs(f);
  };

  const options = [
    {
      value: "B",
      label: "Breakfast",
    },
    {
      value: "L",
      label: "Lunch",
    },
    {
      value: "D",
      label: "Dinner",
    },
  ];

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.itineraries" />} key="1">
            <Form.Item
              label={<IntlMessages id="global.title" />}
              name="title"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.meal" />}
              name="meals"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Checkbox.Group>
                <Row>
                  {options.map((item, index) => {
                    return (
                      <Col key={index}>
                        <Checkbox value={item.value}>{item.label}</Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.ticket" />}
              name="ticket"
              rules={[
                {
                  required: false,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select ticket"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="free">Free</Option>
                <Option value="include">Include</Option>
                <Option value="not_include">Not Include</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="dest_id"
              label={<IntlMessages id="global.destination" />}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <TreeSelect
                style={{
                  width: "100%",
                }}
                dropdownStyle={{
                  maxHeight: 400,
                  overflow: "auto",
                }}
                treeData={treeData}
                placeholder="Please select"
                treeDefaultExpandAll
              />
            </Form.Item>
            <Form.Item
              name="status"
              label={<IntlMessages id="global.status" />}
            >
              <Radio.Group>
                <Radio value={0}>
                  <IntlMessages id="global.inactive" />
                </Radio>
                <Radio value={1}>
                  <IntlMessages id="global.active" />
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.cover_image" />}
              name="cover_image"
            >
              <UploadImage onChangeData={onChangeImage} initList={fileList} />
            </Form.Item>
          </TabPane>
          <TabPane tab={<IntlMessages id="global.description" />} key="2">
            <Form.Item
              label={<IntlMessages id="global.description" />}
              name="description"
              rules={[
                {
                  required: false,
                },
              ]}
              valuePropName="data"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                return data;
              }}
            >
              <CKEditor editor={ClassicEditor} />
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
