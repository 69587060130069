import React, { useEffect, useRef, useState } from "react";

import IntlMessages from "../../components/IntlMessage";
import { Button, Card, Table, Tag, Modal, Switch, Row, Col, Space } from "antd";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import optionsApi from "../../api/options";
import OptionForm from "./OptionForm";
import ActionBar from "../../components/ActionBar";
import formatCurrency from "../../currencyConfigHelper";

export default function PriceMGR(props) {
  const isMount = useRef(false);

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [item, setItem] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const { dataOptions } = props;

  console.log("dataOpt1", dataOptions);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    order_by: "title",
    order_dir: "ASC",
    filter_value: [],
    filter_name: ["tour"],
  });
  const [modal, setModal] = useState(false);

  const onChangeTable = async (
    pagination,
    filters,
    sorter,
    extra = { currentDataSource: [] }
  ) => {
    setFilter({
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order_dir: getOrder(sorter.order),
      order_by: sorter.columnKey ? `${sorter.columnKey}` : "title",
    });
  };
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const payload = { ...filter };
      payload.filter_value = [dataOptions.id];
      let items = await optionsApi.getAll(payload);

      setItems(items.data);
      setLoading(false);
      setDeleting(false);
      if (total != items.total) setTotal(items.total);
    }
    fetchData();
  }, [filter, deleting, reload, dataOptions]);

  const onReload = () => {
    setReload((reload) => !reload);
  };

  const onOpenModal = (record = null) => {
    setModal(true);
    setItem(record);
    console.log(record);
  };

  const onCloseModal = () => {
    setModal(false);
  };
  const intl = useIntl();
  const onRemove = (data) => {
    Modal.confirm({
      title: intl.formatMessage({ id: "global.delete_confirm" }),
      okText: intl.formatMessage({ id: "global.yes" }),
      onOk: async () => {
        await optionsApi.delete(data.id).catch((error) => {
          console.error(error);
          Modal.error({
            title: error.data.message,
            okText: intl.formatMessage({ id: "global.close" }),
          });
          setLoading(false);
        });
        setDeleting(true);
      },
      onCancel() { },
    });
  };
  // const onChangeStatus = async (ischecked, record) => {
  //   console.log(ischecked);
  //   // await (updateService(record.id, { status: ischecked }));

  //   setItems((items) =>
  //     items.map((data) => {
  //       if (data.id === record.id)
  //         return {
  //           ...data,
  //           status: ischecked,
  //         };
  //       return data;
  //     })
  //   );
  // };

  const getOrder = (order) => {
    if (order === "ascend") return "ASC";
    if (order === "descend") return "DESC";
    return "ASC";
  };

  const columns = [
    {
      title: <IntlMessages id="global.title" />,
      key: "title",
      sorter: true,

      dataIndex: "title",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onOpenModal(record)}
            shape="round"
            icon={<EditOutlined />}
          >
            {record.title}
          </Button>
        )
      },
    },

    {
      title: <IntlMessages id="global.Vehicles" />,
      key: "car_id",
      dataIndex: "car_id",

      sorter: true,
      render: (e, record) => {
        return ``
      },
    },

    {
      title: <IntlMessages id="global.max_person" />,
      key: "max_person",
      sorter: true,

      dataIndex: "max_person",
      render: (text, record) => {
        return `${record.max_person}`;
      },
    },

    {
      title: "",
      width: 100,
      align: true,
      render: (text, record) => {
        return (
          <Space>


            <Button
              type="primary"
              size="small"
              onClick={() => onRemove(record)}
              danger
              shape="round"
              icon={<DeleteOutlined />}
            >

            </Button>
          </Space>
        );
      },
    },
  ];
  const { onClose, currentData, open } = props;
  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };
  return (
    <Modal
      title={<IntlMessages id="global.PriceMGMT" />}
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      footer={null}
      width="60%"
    >
      <Card bordered={false} style={{ minHeight: "100%" }}>
        <Row justify="space-between">
          <Col>
            <h2>{`Tour: ${dataOptions.title}`}</h2>
          </Col>
          <Col>
            <ActionBar
              isShowAddButton={true}
              onAdd={onOpenModal}
              onRefresh = {()=>onReload()}
              isShowPublishButtons={false}
              isShowCopyButton={false}
              isShowDeleteButton={false}
              align="right"
            ></ActionBar>
          </Col>
        </Row>

        <Table
          tableLayout="auto"
          columns={columns}
          dataSource={items}
          onChange={onChangeTable}
          rowKey="id"
          size="small"
          loading={loading}
          scroll={{
            x: "max-content",
          }}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20"],
            total: total,
            current: filter.page,
            pageSize: 5,
          }}
        />
        {modal ? (
          <OptionForm
            open={modal}
            onClose={onCloseModal}
            currentData={item}
            onReload={onReload}
            dataTour={dataOptions}
          />
        ) : null}
      </Card>
    </Modal>
  );
}
