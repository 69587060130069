/**
 * Helpers Functions
 */
import moment from 'moment';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
    throw new Error('Bad Hex');
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = '...';
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
}

export function trancWord(str, limit) {
    str = str.split(' ');
    let summ = 0
    for (let [index, value] of str.entries()) {
        summ += value.length
        if (summ > limit) {
            let cutTolimit = str.slice(0, index);
            return str.slice(0, index).join(' ') + ' ' + '...';
        }
    }
    return str.join(' ');
}

/**
 * Get Date
 */
export function getTheDate(timestamp, format) {
    let time = timestamp * 1000;
    let formatDate = format ? format : 'MM-DD-YYYY';
    return moment(time).format(formatDate);
}

/**
 * Convert Date To Timestamp
*/
export function convertDateToTimeStamp(date, format) {
    let formatDate = format ? format : 'YYYY-MM-DD';
    return moment(date, formatDate).unix();
}

export function formatCurrency(value) {
    return (new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value));
}

/**
 * Function to return current app layout
 */
export function getAppLayout(url) {
    let location = url.pathname;
    let path = location.split('/');
    return path[1];
}

/**
 * 
 * @param {int} x 
 * Thousand delimiter
 */
export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

/**
 * 
 * @param {*function} callback 
 * @param {*number} delay 
 * @param  {...any} args 
 * 
 */
export function debounce(callback, delay, ...args) {
    const timerClear = () => clear = true;
    var clear = true;
    return event => {
        if (clear) {
            clear = false;
            setTimeout(timerClear, delay);
            callback(event, ...args);
        }
    }
}


export function capitalize(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export function getFirstLetterOfWords(str) {
    var acronym = '';
    let temp = str.split(" ");
    if (temp.length >= 2) acronym = temp[temp.length - 2].charAt(0) + temp[temp.length - 1].charAt(0);
    else acronym = str.charAt(0);
    // var matches = str.match(/\b(\w)/g);
    // if(!matches) return str.charAt(0);

    return acronym;
}

export function cleanObject(obj) {
    Object.keys(obj).map(key => {
        if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
            delete obj[key];
        }
    });

    return obj;
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

/**
 * render function for breadcrumb
 * @param {*} route 
 * @param {*} params 
 * @param {*} routes 
 * @param {*} paths 
 */
export const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
}


/**
 * 
 * @param {*} url URL need to download
 * Downlaod without pop-up block warning
 */
export const downloadFromLink = (url, filename) => {
    let link = document.createElement("a");
    link.download = filename;
    link.href = url;
    link.click();
    URL.revokeObjectURL(link.href);
}

/**
 * 
 * @param {*} type 
 * mapping customer source
 */


/**
 * Format number thousand seperator
 * @param {*} num original number
 */
export function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}