import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";

import IntlMessages from "../../components/IntlMessage";
import { Button, Card, Table, Modal, Row, Col, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PageTitle from "../../components/PageTitle";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import ActionBar from "../../components/ActionBar";
import currenciesApi from "../../api/currencies";
import CurrenciesForm from "./CurrenciesForm";
import update from "immutability-helper";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import config from "../../config";

Currencies.propTypes = {
  items: PropTypes.array,
};
Currencies.defaultProps = {
  items: [],
};

function Currencies(props) {
  // const isMount = useRef(false)

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [item, setItem] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [condition, setCondition] = useState({});

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    order_by: "title",
    order_dir: "ASC",
  });
  const [modal, setModal] = useState(false);

  const onChangeTable = async (
    pagination,
    filters,
    sorter,
    extra = { currentDataSource: [] }
  ) => {
    setFilter({
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order_dir: getOrder(sorter.order),
      order_by: sorter.columnKey ? `${sorter.columnKey}` : "title",
    });
  };

  const onFilter = debounce(async (value, title, type) => {
    if (type === "search") {
      setFilter((filter) => {
        return { ...filter, keyword: value };
      });
    } else {
      const filter_obj = { ...condition, [title]: value };
      let filter_name = [];
      let filter_value = [];
      for (var key in filter_obj) {
        if (filter_obj[key]) {
          filter_name.push(key);
          filter_value.push(filter_obj[key]);
        }
      }
      setFilter((filter) => {
        return {
          ...filter,
          filter_name: filter_name,
          filter_value: filter_value,
        };
      });
    }
  }, 300);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let item = await currenciesApi.getAll(filter);

      setItems(item.data);
      setLoading(false);
      setDeleting(false);
      if (total != items.total) setTotal(items.total);
    }
    fetchData();
  }, [filter, deleting, reload]);

  const onReload = () => {
    setReload((reload) => !reload);
  };

  const onOpenModal = (record = null) => {
    setModal(true);
    setItem(record);
    console.log(record);
  };

  const onCloseModal = () => {
    setModal(false);
  };
  const intl = useIntl();
  const onRemove = (data) => {
    Modal.confirm({
      title: intl.formatMessage({ id: "global.delete_confirm" }),
      okText: intl.formatMessage({ id: "global.yes" }),
      onOk: async () => {
        await currenciesApi.delete(data.id);
        setDeleting(true);
      },
      onCancel() { },
    });
  };

  //Drag table
  const type = "DraggableBodyRow";

  const DraggableBodyRow = ({
    index,
    moveRow,
    className,
    style,
    ...restProps
  }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};

        if (dragIndex === index) {
          return {};
        }

        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index ? " drop-over-downward" : " drop-over-upward",
        };
      },
      drop: (item) => {
        moveRow(item.index, index);
      },
    });
    const [, drag] = useDrag({
      type,
      item: {
        index,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ""}`}
        style={{
          cursor: "move",
          ...style,
        }}
        {...restProps}
      />
    );
  };
  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = items[dragIndex];
      setItems(
        update(items, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [items]
  );
  // const onChangeStatus = async (ischecked, record) => {
  //   console.log(ischecked);
  //   await currenciesApi.update(record.id, { status: ischecked });
  //   setItems((items) =>
  //     items.map((data) => {
  //       if (data.id === record.id)
  //         return {
  //           ...data,
  //           status: ischecked,
  //         };
  //       return data;
  //     })
  //   );
  // };

  const getOrder = (order) => {
    if (order === "ascend") return "ASC";
    if (order === "descend") return "DESC";
    return "ASC";
  };

  const columns = [
    {
      title: <IntlMessages id="global.id_number" />,
      key: "id",
      width: 20,
      sorter: true,
      dataIndex: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: <IntlMessages id="currency.image" />,
      key: "image",
      sorter: true,

      dataIndex: "image",
      render: (text, record) => {
        return (
          record.image ?
            <img
              src={`${config.MEDIA_URL}${record.image}`}
              width="30"
              height="30"
              alt=""
            ></img> : ``
        );
      },
    },
    {
      title: <IntlMessages id="currency.name" />,
      with: 40,
      key: "name",
      sorter: true,

      dataIndex: "name",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onOpenModal(record)}
            shape="round"
            icon={<EditOutlined />}
          >
            {record.name}
          </Button>
        );
      },
    },

    {
      title: <IntlMessages id="currency.exchange_rate" />,
      key: "exchange_rate",
      sorter: true,
      dataIndex: "exchange_rate",
      render: (text, record) => {
        return `${record.exchange_rate}`;
      },
    },

    // {
    //   title: <IntlMessages id="global.images" />,
    //   key: "images",
    //   sorter: true,

    //   dataIndex: "images",
    //   render: (text, record) => {
    //     return (
    //       //<IntlMessages id={`group.${record.description}`} />;
    //       `${record.images}`
    //     );
    //   },
    // },
    // {
    //   title: <IntlMessages id="global.code" />,
    //   key: "code",
    //   sorter: true,

    //   dataIndex: "code",
    //   render: (text, record) => {
    //     return (
    //       //<IntlMessages id={`group.${record.description}`} />;
    //       `${record.code}`
    //     );
    //   },
    // },

    {
      title: "",
      width: 100,
      align: true,
      render: (text, record) => {
        return (
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => onRemove(record)}
              danger
              shape="round"
              icon={<DeleteOutlined />}
            >

            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Card bordered={false} style={{ minHeight: "100%" }}>
      <Row justify="space-between">
        <Col>
          {" "}
          <PageTitle title={<IntlMessages id="global.currencies" />} />
        </Col>

        <Col>
          <ActionBar
            isShowAddButton={true}
            onAdd={onOpenModal}
            onRefresh = {()=>onReload()}
            isShowPublishButtons={false}
            isShowCopyButton={false}
            isShowDeleteButton={false}
          ></ActionBar>
        </Col>
      </Row>

      {/* <FilterBar

                onFilter={onFilter}
                data={[
                    {
                        name: "category",
                        data: categories,
                        placeholder: <IntlMessages id="global.select_category" />,
                    },

                ]}

            >
            </FilterBar> */}
      <DndProvider backend={HTML5Backend}>
        <Table
          tableLayout="auto"
          columns={columns}
          dataSource={items}
          onChange={onChangeTable}
          rowKey="id"
          size="small"
          loading={loading}
          // scroll={{
          //   x: "max-content",
          // }}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50"],
            total: total,
            current: filter.page,
            pageSize: 10,
          }}
          components={components}
          onRow={(_, index) => {
            const attr = {
              index,
              moveRow,
            };
            return attr;
          }}
        />
      </DndProvider>
      {modal ? (
        <CurrenciesForm
          open={modal}
          onClose={onCloseModal}
          currentData={item}
          onReload={onReload}
        />
      ) : null}
    </Card>
  );
}

export default Currencies;
