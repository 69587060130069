import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal, Form, Input, Button, Radio, Select, Tabs } from "antd";

import IntlMessages from "../../components/IntlMessage";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useIntl } from "react-intl";
import postApi from "../../api/posts";

import UploadImage from "../../components/Elements/UploadImage";
import config from "../../config";
import categoriesApi from "../../api/categories";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
export default function PostForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [cat, setCat] = useState([]);
  //console.log("?",loading)
  const intl = useIntl();
  useEffect(() => {
    isMount.current = true;
    async function fetchData() {
      if (isMount) {
        var filter = {
          page: 1,
          limit: 1000,
          keyword: "",
          order_by: "id",
          order_dir: "DESC",
        };
        let categories = await categoriesApi.getAll(filter);
        setCat(categories.data);
      }
    }
    fetchData();
    return () => {
      isMount.current = false;
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };
        data.featured = data.featured ? 1 : 0;
        delete data.image;
        delete data.catIds;
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        if (imgs) {
          formData.append("image", imgs);
        }
        form
          .getFieldValue("catIds")
          .map((data) => formData.append("catIds[]", data));
        if (currentData) {
          postApi
            .update(currentData.id, formData)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        } else {
          postApi
            .create(formData)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };

  let initialValue = {
    title: currentData && currentData.title ? currentData.title : "",
    fulltext: currentData && currentData.fulltext ? currentData.fulltext : "",
    image: currentData && currentData.image ? currentData.image : "",
    metakey: currentData && currentData.metakey ? currentData.metakey : "",
    short: currentData && currentData.short ? currentData.short : "",
    metadesc: currentData && currentData.metadesc ? currentData.metadesc : "",
    catIds:
      currentData && currentData.categories
        ? currentData.categories.map((d) => d.id)
        : [],
    status: currentData && currentData.status ? 1 : 0,
    featured: !!(currentData && currentData.featured),
  };

  console.log("initialValue", currentData);

  const fileList = useMemo(
    () =>
      currentData && currentData.image
        ? [
            {
              uid: "1",
              name: "image.png",
              status: "done ",
              url: `${config.MEDIA_URL}${currentData.image}`,
            },
          ]
        : [],
    [currentData]
  );

  const [imgs, setImgs] = useState(null);

  const onChangeImage = (v, f) => {
    setImgs(f);
  };

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.posts" />} key="1">
            <Form.Item
              label={<IntlMessages id="global.title" />}
              name="title"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="catIds"
              label={<IntlMessages id="global.category" />}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                mode="multiple"
                showSearch
                allowClear
                placeholder="Select Categories"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {cat.map((option, index) => {
                  return (
                    <Option key={`${option.id}_${index}`} value={option.id}>
                      {option.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="post.metakey" />}
              name="metakey"
              rules={[
                {
                  required: false,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="post.metadesc" />}
              name="metadesc"
              rules={[
                {
                  required: false,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="featured"
              label={<IntlMessages id="global.featured" />}
              valuePropName="checked"
            >
              <Checkbox defaultChecked={initialValue.featured ? true : false}>
                Special
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="status"
              label={<IntlMessages id="global.status" />}
            >
              <Radio.Group>
                <Radio value={0}>
                  <IntlMessages id="global.inactive" />
                </Radio>
                <Radio value={1}>
                  <IntlMessages id="global.active" />
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label={<IntlMessages id="global.image" />} name="image">
              <UploadImage onChangeData={onChangeImage} initList={fileList} />
            </Form.Item>
          </TabPane>
          <TabPane tab={<IntlMessages id="post.short" />} key="2">
            <Form.Item
              label={<IntlMessages id="post.short" />}
              name="short"
              valuePropName="data"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                return data;
              }}
            >
              <CKEditor editor={ClassicEditor} />
            </Form.Item>
          </TabPane>
          <TabPane tab={<IntlMessages id="post.fulltext" />} key="3">
            <Form.Item
              label={<IntlMessages id="post.fulltext" />}
              name="fulltext"
              rules={[
                {
                  required: false,
                },
              ]}
              valuePropName="data"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                return data;
              }}
            >
              <CKEditor editor={ClassicEditor} />
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
