import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Tabs,
} from "antd";
import IntlMessages from "../../components/IntlMessage";
import { useIntl } from "react-intl";
import operatorsApi from "../../api/operators";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function OperatorsForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  //console.log("?",loading)
  const intl = useIntl();
  useEffect(() => {
    return () => {
      isMount.current = false;
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };
        //data.propertytypes = JSON.stringify(data.propertytypes);
        console.log("values", data);

        if (currentData) {
          operatorsApi
            .update(currentData.id, data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        } else {
          operatorsApi
            .create(data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };

  let initialValue = {
    title: currentData && currentData.title ? currentData.title : "",
    description:
      currentData && currentData.description ? currentData.description : "",
    firstname:
      currentData && currentData.firstname ? currentData.firstname : "",
    lastname: currentData && currentData.lastname ? currentData.lastname : "",
    email: currentData && currentData.email ? currentData.email : "",
    phone: currentData && currentData.phone ? currentData.phone : "",

    // clinic_id: currentData && currentData.clinic_id ? currentData.clinic_id : "",
    // unit: currentData && currentData.unit ? currentData.unit : "",
    // description: currentData && currentData.description ? currentData.description : "",
    // name: currentData && currentData.name ? currentData.name : "",
    // price: currentData && currentData.price ? currentData.price : "",
    // //supplies_price: currentData && currentData.supplies_price ? currentData.supplies_price : "",
    // warranty_desc: currentData && currentData.warranty_desc ? currentData.warranty_desc : "",
    // is_warrantable: currentData && currentData.is_warrantable ? currentData.is_warrantable : false,
    // status: currentData && currentData.status ? currentData.status : true,
    // category: currentData && currentData.category ? currentData.category : "",
    // suppliers: currentData && currentData.suppliers ? JSON.parse(currentData.suppliers) : [
    //     { supplier_id: "", price: "" }

    //]
  };

  //console.log("initialValue", initialValue);

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.operators" />} key="1">
            <Form.Item
              label={<IntlMessages id="global.title" />}
              name="title"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.description" />}
              name="description"
              rules={[
                {
                  required: true,
                },
              ]}
              valuePropName="data"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                return data;
              }}
            >
              <CKEditor editor={ClassicEditor} />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.lastname" />}
              name="lastname"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.firstname" />}
              name="firstname"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.email" />}
              name="email"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.phone" />}
              name="phone"
              rules={[
                {
                  required: false,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
