import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import IntlMessages from "../../components/IntlMessage";
import { Button, Card, Table, Tag, Modal, Switch, Row, Col, Space, Typography } from "antd";
import TableActionBar from "../../components/FilterBar";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PageTitle from "../../components/PageTitle";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import ActionBar from "../../components/ActionBar";
import orderApi from "../../api/order";
import formatCurrency from "../../currencyConfigHelper";
import moment from "moment";


import { trancWord } from "../../utils/helpers";

Orders.propTypes = {
  items: PropTypes.array,
};
Orders.defaultProps = {
  items: [],
};
const { Text } = Typography;
function Orders(props) {
  // const isMount = useRef(false)

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [item, setItem] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [condition, setCondition] = useState({});

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    order_by: "id",
    order_dir: "ASC",
  });
  const [modal, setModal] = useState(false);

  const onChangeTable = async (
    pagination,
    filters,
    sorter,
    extra = { currentDataSource: [] }
  ) => {
    setFilter({
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order_dir: getOrder(sorter.order),
      order_by: sorter.columnKey ? `${sorter.columnKey}` : "id",
    });
  };

  const onFilter = debounce(async (value, title, type) => {
    if (type === "search") {
      setFilter((filter) => {
        return { ...filter, keyword: value };
      });
    } else {
      const filter_obj = { ...condition, [title]: value };
      let filter_name = [];
      let filter_value = [];
      for (var key in filter_obj) {
        if (filter_obj[key]) {
          filter_name.push(key);
          filter_value.push(filter_obj[key]);
        }
      }
      setFilter((filter) => {
        return {
          ...filter,
          filter_name: filter_name,
          filter_value: filter_value,
        };
      });
    }
  }, 300);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let items = await orderApi.getAll(filter);

      setItems(items.data);
      setLoading(false);
      setDeleting(false);
      if (total != items.total) setTotal(items.total);
    }
    fetchData();
  }, [filter, deleting, reload]);

  const onReload = () => {
    setReload((reload) => !reload);
  };

  const onOpenModal = (record = null) => {
    setModal(true);
    setItem(record);
    console.log(record);
  };
  console.log("item", items);
  const intl = useIntl();
  // const onRemove = (data) => {
  //   Modal.confirm({
  //     title: intl.formatMessage({ id: "global.delete_confirm" }),
  //     okText: intl.formatMessage({ id: "global.yes" }),
  //     onOk: async () => {
  //       await orderApi.delete(data.id);
  //       setDeleting(true);
  //     },
  //     onCancel() {},
  //   });
  // };
  // const onChangeStatus = async (ischecked, record) => {
  //   console.log(ischecked);
  //   // await (updateService(record.id, { status: ischecked }));

  //   setItems((items) =>
  //     items.map((data) => {
  //       if (data.id === record.id)
  //         return {
  //           ...data,
  //           status: ischecked,
  //         };
  //       return data;
  //     })
  //   );
  // };

  const getOrder = (order) => {
    if (order === "ascend") return "ASC";
    if (order === "descend") return "DESC";
    return "ASC";
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  // const onMultiRemove = (selectedRowKeys) => {
  //   Modal.confirm({
  //     title: intl.formatMessage({ id: "global.delete_confirm" }),
  //     okText: intl.formatMessage({ id: "global.yes" }),
  //     onOk: async () => {
  //       await orderApi.delete(selectedRowKeys);
  //       setDeleting(true);
  //     },
  //     onCancel() { },
  //   });
  // };

  const onDelete = async (selectedRowKeys) => {
    console.log("Deleted")
    await orderApi.delete(selectedRowKeys).catch((error) => {
      console.error(error);
      Modal.error({
        title: error.data.message,
        okText: intl.formatMessage({ id: "global.close" }),
      });
      setLoading(false);
    });
    setDeleting(true);
  };


  const columns = [
    {
      title: <IntlMessages id="global.orderId" />,
      key: "id",
      width: 100,
      dataIndex: "order_no",
      render: (text, record, index) => {
        return record.order_no;
      },
    },
    {
      title: <IntlMessages id="order.tour_title" />,
      key: "tour_title",
      sorter: true,

      dataIndex: "tour_title",
      render: (text, record) => {
        return (
          <>
            <Text>{trancWord(record.option.tour.title, 30)}</Text>
            <br />
            <Text> {record.option.title}</Text>
          </>
        );
      },
    },

    {
      title: <IntlMessages id="order.orderStatus" />,
      key: "orderStatus",
      sorter: true,

      dataIndex: "orderStatus",
      render: (text, record) => {
        return record.order_status === "pending" ? (
          <Tag color="processing">{record.order_status}</Tag>
        ) : record.order_status === "confirmed" ? (
          <Tag color="success">{record.order_status}</Tag>
        ) : (
          <Tag color="error">{record.order_status}</Tag>
        );
      },
    },
    {
      title: <IntlMessages id="order.payStatus" />,
      key: "payStatus",
      sorter: true,

      dataIndex: "payStatus",
      render: (text, record) => {
        return record.pay_status === "pending" ||
          record.pay_status === "confirmed" ? (
          <Tag color="processing">{record.pay_status}</Tag>
        ) : record.pay_status === "success" ? (
          <Tag color="success">{record.pay_status}</Tag>
        ) : (
          <Tag color="error">{record.pay_status}</Tag>
        );
      },
    },
    {
      title: <IntlMessages id="order.departDate" />,
      key: "departDate",
      sorter: true,

      dataIndex: "departDate",
      render: (text, record) => {
        return moment(record.depart_date).format("YYYY/MM/DD");
      },
    },
    {
      title: <IntlMessages id="order.customer" />,
      key: "fullname",
      sorter: true,

      dataIndex: "fullname",
      render: (text, record) => {
        return record.customer.fullname;
      },
    },
    {
      title: <IntlMessages id="order.totalTravelers" />,
      key: "qty",
      sorter: true,

      dataIndex: "qty",
      render: (text, record) => {
        return (
          //<IntlMessages id={`group.${record.description}`} />;
          `${record.qty} Travelers`
        );
      },
    },
    {
      title: <IntlMessages id="order.totalPrice" />,
      key: "total",
      sorter: true,

      dataIndex: "total",
      render: (text, record) => {
        return formatCurrency(record.total);
      },
    },
    {
      title: <IntlMessages id="order.created" />,
      key: "created_at",
      sorter: true,

      dataIndex: "created_at",
      render: (text, record) => {
        return moment(record.created_at).format("YYYY/MM/DD");
      },
    },

    // {
    //   title: <IntlMessages id="global.status" />,
    //   dataIndex: "status",
    //   width: 100,
    //   key: "status",
    //   render: (e, record) =>
    //     record ? (
    //       <Switch
    //         checkedChildren={<IntlMessages id="global.published" />}
    //         unCheckedChildren={<IntlMessages id="global.unpublished" />}
    //         defaultChecked={e}
    //         onChange={(ischecked) => onChangeStatus(ischecked, record)}
    //       />
    //     ) : null,
    // },

    // {
    //   // title: <IntlMessages id="global.mobile" />,
    //   title: "",
    //   width: 100,
    //   align: true,
    //   render: (text, record) => {
    //     return (
    //       <Space>
    //         {/* <Button
    //           type="primary"
    //           size="small"
    //           onClick={() => onOpenModal(record)}
    //           shape="round"
    //           icon={<EditOutlined />}
    //         >
    //           <IntlMessages id="global.edit" />
    //         </Button> */}
    //         <Button
    //           type="primary"
    //           size="small"
    //           onClick={() => onRemove(record)}
    //           danger
    //           shape="round"
    //           icon={<DeleteOutlined />}
    //         >
    //           <IntlMessages id="global.delete" />
    //         </Button>
    //       </Space>
    //     );
    //   },
    // },
  ];

  const dataTable = (items || []).map(i => {
    const r = i;
    delete r.children;
    return r;
  })

  return (
    <Card bordered={false} style={{ minHeight: "100%" }}>
      <Row justify="space-between">
        <Col >
          {" "}
          <PageTitle title={<IntlMessages id="global.order" />} />
          {/* <Button
            type="primary"
            size="small"
            onClick={() => onMultiRemove(selectedRowKeys)}
            danger
            shape="round"
            disabled={!hasSelected}
            icon={<DeleteOutlined />}
          >
            <IntlMessages id="global.delete" />
          </Button> */}
        </Col>

        <Col >
          <ActionBar
            onDelete={() => onDelete(selectedRowKeys)}
            isShowAddButton={true}
            //onAdd={onOpenModal}
            onRefresh = {()=>onReload()}
            isShowPublishButtons={false}
            isShowCopyButton={false}
            isShowDeleteButton={true}
            isDisabled={!hasSelected}
          ></ActionBar>
        </Col>
      </Row>

      {/* <FilterBar

                onFilter={onFilter}
                data={[
                    {
                        name: "category",
                        data: categories,
                        placeholder: <IntlMessages id="global.select_category" />,
                    },

                ]}

            >
            </FilterBar> */}

      <Table
        tableLayout="auto"
        columns={columns}
        dataSource={dataTable}
        onChange={onChangeTable}
        rowKey="id"
        rowSelection={rowSelection}
        size="small"
        loading={loading}
        scroll={{
          x: "max-content",
        }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"],
          total: total,
          current: filter.page,
          pageSize: filter.limit,
        }}
      />
    </Card>
  );
}

export default Orders;
