import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  DatePicker,
  Button,
  Radio,
  Switch,
  Select,
  Tabs,
  InputNumber,
  Space,
  Upload,
} from "antd";
import IntlMessages from "../../components/IntlMessage";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useIntl } from "react-intl";
import imagesApi from "../../api/images";
import config from "../../config";
import { UploadOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function Gallery(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open, galleryData } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const intl = useIntl();
  const [allGallery, setAllGallery] = useState([]);
  console.log("current", galleryData);
  useEffect(() => {
    isMount.current = true;
    async function fetchData() {
      if (isMount) {
        let gallery = await imagesApi.get(galleryData.id);
        setAllGallery(gallery);
        setFirstLoading(false);
      }
    }
    fetchData();
    return () => {
      isMount.current = false;
    };
  }, []);

  const [listgallery, setListGallery] = useState([]);

  const handleChangFileList = ({ fileList }) => {
    console.log(fileList);
    fileList.map((data) => {
      data.status = "done";
    });
    setListGallery(fileList);
  };
  console.log("123", listgallery);
  const handleSubmit = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        if (listgallery) {
          const formDataImg = new FormData();
            listgallery.map((data) => {
              if (data.originFileObj!==undefined)
                formDataImg.append("files", data.originFileObj);
            });
          imagesApi
            .create(galleryData.id, formDataImg)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch(() => setLoading(false));
        }
      })
      .catch(() => setLoading(false));
  };

  const allListGallery = [];

  if (galleryData)
    allGallery.map((data, index) => {
      allListGallery.push({
        uid: `${data.id}`,
        name: `${data.title.substring(22, data.title.length)}`,
        status: "done",
        url: `${config.MEDIA_URL}${data.title}`,
      });
    });

  console.log("list gallery", allListGallery);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };

  let initialValue = {};

  return (
    <Modal
      title={<IntlMessages id="global.gallerySetting" />}
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      {!firstLoading && (
        <Form
          {...layout}
          form={form}
          labelAlign="left"
          initialValues={initialValue}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label={<IntlMessages id="tour.gallery" />} name="files">
            <Space
              direction="vertical"
              style={{
                width: "100%",
              }}
              size="large"
            >
              <Upload
                listType="picture"
                maxCount={20}
                multiple
                onChange={handleChangFileList}
                defaultFileList={allListGallery}
              >
                <Button icon={<UploadOutlined />}>Upload (Max: 20)</Button>
              </Upload>
            </Space>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
