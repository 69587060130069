import React, { useEffect, useRef, useState } from "react";

import IntlMessages from "../../components/IntlMessage";
import { Button, Card, Table, Tag, Modal, Switch, Row, Col, Space, Anchor } from "antd";


import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { debounce } from "lodash";
import ActionBar from "../../components/ActionBar";
import FilterBar from "../../components/FilterBar";
import itinerariesApi from "../../api/itineraries";
import ItinerariesForm from "./ItinerariesForm";
import destinationsApi from "../../api/destinations";
import config from "../../config";
const { Link } = Anchor;
export default function ItinerariesMGR(props) {
  const isMount = useRef(false);

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [item, setItem] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [condition, setCondition] = useState({});
  //const [tour, setTour] = useState([]);
  const [destination, setDestination] = useState([]);
  const { dataiti } = props;

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    order_by: "id",
    order_dir: "ASC",
    filter_value: [],
    filter_name: ["tour"],
  });
  const [modal, setModal] = useState(false);

  const onChangeTable = async (
    pagination,
    filters,
    sorter,
    extra = { currentDataSource: [] }
  ) => {
    setFilter({
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order_dir: getOrder(sorter.order),
      order_by: sorter.columnKey ? `${sorter.columnKey}` : "title",
    });
  };

  const onFilter = debounce(async (value, title, type) => {
    if (type === "search") {
      setFilter((filter) => {
        return { ...filter, keyword: value };
      });
    } else {
      const filter_obj = { ...condition, [title]: value };
      let filter_name = [];
      let filter_value = [];
      for (var key in filter_obj) {
        if (filter_obj[key]) {
          filter_name.push(key);
          filter_value.push(filter_obj[key]);
        }
      }
      setFilter((filter) => {
        return {
          ...filter,
          filter_name: filter_name,
          filter_value: filter_value,
        };
      });
    }
  }, 300);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const payload = { ...filter };
      payload.filter_value = [dataiti.id];
      let items = await itinerariesApi.getAll(payload);

      setItems(items.data);
      setLoading(false);
      setDeleting(false);
      if (total != items.total) setTotal(items.total);
    }
    fetchData();
  }, [filter, deleting, reload, dataiti]);

  useEffect(() => {
    isMount.current = true;
    async function fetchData() {
      if (isMount) {
        var filter = {
          page: 1,
          limit: 1000,
          keyword: "",
          order_by: "id",
          order_dir: "DESC",
        };
        let dest = await destinationsApi.getAll(filter);
        setDestination(dest.data);
      }
    }
    fetchData();
    return () => {
      isMount.current = false;
    };
  }, []);

  const onReload = () => {
    setReload((reload) => !reload);
  };

  const onOpenModal = (record = null) => {
    setModal(true);
    setItem(record);
    console.log(record);
  };

  const onCloseModal = () => {
    setModal(false);
  };
  const intl = useIntl();
  const onRemove = (data) => {
    Modal.confirm({
      title: intl.formatMessage({ id: "global.delete_confirm" }),
      okText: intl.formatMessage({ id: "global.yes" }),
      onOk: async () => {
        await itinerariesApi.delete(data.id).catch((error) => {
          console.error(error);
          Modal.error({
            title: error.data.message,
            okText: intl.formatMessage({ id: "global.close" }),
          });
          setLoading(false);
        });
        setDeleting(true);
      },
      onCancel() { },
    });
  };
  const onChangeStatus = async (ischecked, record) => {
    console.log(ischecked);
    await itinerariesApi.update(record.id, { status: ischecked });

    setItems((items) =>
      items.map((data) => {
        if (data.id === record.id)
          return {
            ...data,
            status: ischecked,
          };
        return data;
      })
    );
  };

  const getOrder = (order) => {
    if (order === "ascend") return "ASC";
    if (order === "descend") return "DESC";
    return "ASC";
  };

  const columns = [
    {
      title: <IntlMessages id="global.id_number" />,
      key: "id",
      width: 20,
      sorter: true,
      dataIndex: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    // {
    //   title: <IntlMessages id="global.cover_image" />,
    //   key: "cover_image",
    //   sorter: true,

    //   dataIndex: "cover_image",
    //   render: (text, record) => {
    //     return record.cover_image ? (
    //       <img
    //         src={`${config.MEDIA_URL}${record.cover_image}`}
    //         width="30"
    //         height="35"
    //         alt=""
    //       ></img>
    //     ) : (
    //       ``
    //     );
    //   },
    // },
    {
      title: <IntlMessages id="global.title" />,
      key: "title",
      sorter: true,

      dataIndex: "title",
      render: (text, record) => {
        return (
          <Button

            size="small"
            onClick={() => onOpenModal(record)}
            shape="round"
          >
            {record.title}
          </Button>
        );
      },
    },
    {
      title: <IntlMessages id="global.destination" />,
      key: "destination",
      sorter: true,

      dataIndex: "destination",
      render: (text, record) => {
        return `${record.destination.title}`;
      },
    },
    {
      title: <IntlMessages id="global.status" />,
      dataIndex: "status",
      width: 100,
      key: "status",
      render: (e, record) =>
        record ? (
          <Switch
            checkedChildren={""}
            unCheckedChildren={""}
            checked={!!e}
            onChange={(ischecked) => onChangeStatus(ischecked, record)}
          />
        ) : null,
    },
    {
      title: "",
      width: 100,
      align: true,
      render: (text, record) => {
        return (
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => onRemove(record)}
              danger
              shape="round"
              icon={<DeleteOutlined />}
            >

            </Button>
          </Space>
        );
      },
    },
  ];
  const { onClose, currentData, open } = props;
  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };
  return (
    <Modal
      title={<IntlMessages id="global.itinerariesMGMT" />}
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      footer={null}
      width="60%"
    >
      <Card bordered={false} style={{ minHeight: "100%" }}>
        <Row justify="space-between">
          <Col>
            <h2>{`Tour: ${dataiti.title}`}</h2>
          </Col>
          <Col>
            <ActionBar
              isShowAddButton={true}
              onAdd={onOpenModal}
              onRefresh = {()=>onReload()}
              isShowPublishButtons={false}
              isShowCopyButton={false}
              isShowDeleteButton={false}
              align="right"
            ></ActionBar>
          </Col>
        </Row>
        {/* <FilterBar
          onFilter={onFilter}
          data={[
            {
              name: "destination",
              data: destination,
              placeholder: <IntlMessages id="global.select_destination" />,
            },
          ]}
        >  
        </FilterBar> */}

        <Table
          tableLayout="auto"
          columns={columns}
          dataSource={items}
          onChange={onChangeTable}
          rowKey="id"
          size="small"
          loading={loading}
          scroll={{
            x: "max-content",
          }}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20"],
            total: total,
            current: filter.page,
            pageSize: 10,
          }}
        />
        {modal ? (
          <ItinerariesForm
            open={modal}
            onClose={onCloseModal}
            currentData={item}
            onReload={onReload}
            dataTour={dataiti}
          />
        ) : null}
      </Card>
    </Modal>
  );
}
