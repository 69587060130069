import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Radio,
  Select,
  Tabs,
  TimePicker,
  InputNumber,
} from "antd";
import moment from "moment";
import IntlMessages from "../../components/IntlMessage";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useIntl } from "react-intl";
import tourApi from "../../api/tour";
import operatorsApi from "../../api/operators";
import categoriesApi from "../../api/categories";
import UploadImage from "../../components/Elements/UploadImage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import destinationsApi from "../../api/destinations";
import config from "../../config";
import { InfoCircleOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function ToursForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  //filter-category-khai bao
  const [categories, setCategories] = useState([]);
  //filter-operator-khai bao
  const [operators, setOpertors] = useState([]);
  //filter-destinationIds
  const [dest, setDest] = useState([]);
  const format = "HH:mm";
  const intl = useIntl();
  useEffect(() => {
    isMount.current = true;
    async function fetchData() {
      if (isMount) {
        var filter = {
          page: 1,
          limit: 1000,
          keyword: "",
          order_by: "id",
          order_dir: "DESC",
        };
        let operators = await operatorsApi.getAll(filter);
        let cat = await categoriesApi.getAll(filter);
        let destination = await destinationsApi.getAll(filter);
        setOpertors(operators.data);
        setCategories(cat.data);
        setDest(destination.data);
      }
    }
    fetchData();
    return () => {
      isMount.current = false;
    };
  }, []);

  const tourTypes = {
    Tour: "tour",
    Activity: "activity",
    Ticket: "ticket",
    Transport: "transport",
  };

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };
        data.featured = data.featured ? 1 : 0;
        data.free_cancel = data.free_cancel ? 1 : 0;
        //data.propertytypes = JSON.stringify(data.propertytypes);
        delete data.cover_image;
        delete data.destinationIds;
        delete data.files;
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        if (imgs) {
          formData.append("cover_image", imgs);
        }
        //add list destinationIds
        form
          .getFieldValue("destinationIds")
          .map((data) => formData.append("destinationIds[]", data));
        if (currentData) {
          tourApi
            .update(currentData.id, formData)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        } else {
          tourApi
            .create(formData)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };
  let initialValue = {
    title: currentData && currentData.title ? currentData.title : "",
    description:
      currentData && currentData.description ? currentData.description : "",
    inclusion:
      currentData && currentData.inclusion ? currentData.inclusion : "",
    exclusion:
      currentData && currentData.exclusion ? currentData.exclusion : "",
    type: currentData && currentData.type ? currentData.type : "daytrip",
    duration_unit:
      currentData && currentData.duration_unit
        ? currentData.duration_unit
        : "hour",
    duration: currentData && currentData.duration ? currentData.duration : "",
    start_time:
      currentData && currentData.start_time
        ? moment(currentData.start_time.substring(16, 21), format)
        : moment("08:00", format),
    cat_id:
      currentData && currentData.category && currentData.category.id
        ? currentData.category.id
        : "",
    operator_id:
      currentData && currentData.operator && currentData.operator.id
        ? currentData.operator.id
        : "",
    cover_image:
      currentData && currentData.cover_image ? currentData.cover_image : "",
    images: currentData && currentData.images ? currentData.images : "",
    destinationIds:
      currentData && currentData.destinations
        ? currentData.destinations.map((data) => data.id)
        : [],
    status: currentData && currentData.status ? 1 : 0,
    featured: !!(currentData && currentData.featured),
    group: currentData && currentData.group ? 1 : 0,
    min_person:
      currentData && currentData.min_person ? currentData.min_person : 2,
    max_person:
      currentData && currentData.max_person ? currentData.max_person : 10,
    free_cancel_hours:
      currentData && currentData.free_cancel_hours
        ? currentData.free_cancel_hours
        : 24,
    free_cancel: !!(currentData && currentData.free_cancel),
    meeting_point:
      currentData && currentData.meeting_point ? currentData.meeting_point : "",
    meeting_point_notes:
      currentData && currentData.meeting_point_notes
        ? currentData.meeting_point_notes
        : "",
    cut_off_time:
      currentData && currentData.cut_off_time ? currentData.cut_off_time : 0,
    cancel_policy:
      currentData && currentData.cancel_policy ? currentData.cancel_policy : "",
  };
  console.log("current", currentData);
  const fileList = useMemo(
    () =>
      currentData && currentData.cover_image
        ? [
          {
            uid: "1",
            name: "image.png",
            status: "done ",
            url: `${config.MEDIA_URL}${currentData.cover_image}`,
          },
        ]
        : [],
    [currentData]
  );
  const [imgs, setImgs] = useState(null);

  const onChangeImage = (v, f) => {
    setImgs(f);
  };
  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.tour" />} key="1">
            <Form.Item
              label={<IntlMessages id="global.title" />}
              name="title"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.type" />}
              name="type"
              rules={[
                {
                  required: false,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {
                  Object.entries(tourTypes).map(([key, value]) => (
                    <Option key={value}>{key}</Option>

                  ))};
              </Select>
            </Form.Item>

            <Form.Item
              name="cat_id"
              label={<IntlMessages id="global.category" />}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a category"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {categories.map((option, index) => {
                  return (
                    <Option key={`${option.id}_${index}`} value={option.id}>
                      {option.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="operator_id"
              label={<IntlMessages id="global.operator" />}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a operator"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {operators.map((option, index) => {
                  return (
                    <Option key={`${option.id}_${index}`} value={option.id}>
                      {option.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="destinationIds"
              label={<IntlMessages id="global.destination" />}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                placeholder="Select destinations"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {dest.map((option, index) => {
                  return (
                    <Option key={`${option.id}_${index}`} value={option.id}>
                      {option.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.min_person" />}
              name="min_person"
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.max_person" />}
              name="max_person"
            >
              <InputNumber />
            </Form.Item>

            <Form.Item label={<IntlMessages id="tour.pricing" />} name="group">
              <Radio.Group>
                <Radio value={0}>
                  <IntlMessages id="global.perPerson" />
                </Radio>
                <Radio value={1}>
                  <IntlMessages id="global.perGroup" />
                </Radio>
              </Radio.Group>

              {/* <Radio
                defaultChecked={initialValue.group ? true : false}
              ></Radio> */}
            </Form.Item>

            <Form.Item
              name="featured"
              label={<IntlMessages id="tour.featured" />}
              valuePropName="checked"
            >
              <Checkbox defaultChecked={initialValue.featured ? true : false}>
                Special
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="status"
              label={<IntlMessages id="global.status" />}
            >
              <Radio.Group>
                <Radio value={0}>
                  <IntlMessages id="global.inactive" />
                </Radio>
                <Radio value={1}>
                  <IntlMessages id="global.active" />
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.cover_image" />}
              name="cover_image"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <UploadImage onChangeData={onChangeImage} initList={fileList} />
            </Form.Item>
          </TabPane>
          <TabPane tab={<IntlMessages id="global.freeCancel" />} key="7">
            <Form.Item
              label={<IntlMessages id="global.freeCancelHours" />}
              name="free_cancel_hours"
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="free_cancel"
              label={<IntlMessages id="tour.freeCancel" />}
              valuePropName="checked"
            >
              <Checkbox
                defaultChecked={initialValue.free_cancel ? true : false}
              ></Checkbox>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.cancelPolicy" />}
              name="cancel_policy"
              rules={[
                {
                  required: false,
                },
              ]}
              valuePropName="data"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                return data;
              }}
            >
              <CKEditor editor={ClassicEditor} />
            </Form.Item>
          </TabPane>
          <TabPane tab={<IntlMessages id="tour.time_mgmt" />} key="6">
            <Form.Item
              label={<IntlMessages id="tour.duration_unit" />}
              name="duration_unit"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select duration unit"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="day">Day</Option>
                <Option value="hour">Hour</Option>
                <Option value="minute">Minute</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="tour.duration" />}
              name="duration"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="tour.start_time" />}
              name="start_time"
            >
              <TimePicker format={format} />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="tour.cutOffTime" />}
              tooltip={<IntlMessages id="tooltip.cutOffTime" />}
              name="cut_off_time"
            >
              <InputNumber />
            </Form.Item>
          </TabPane>
          <TabPane tab={<IntlMessages id="global.meeting" />} key="8">
            <Form.Item
              label={<IntlMessages id="global.meetingPoint" />}
              name="meeting_point"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.meetingPointNotes" />}
              name="meeting_point_notes"
            >
              <Input />
            </Form.Item>
          </TabPane>
          <TabPane tab={<IntlMessages id="global.description" />} key="2">
            <Form.Item
              label={<IntlMessages id="global.description" />}
              name="description"
              rules={[
                {
                  required: false,
                },
              ]}
              valuePropName="data"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                return data;
              }}
            >
              <CKEditor editor={ClassicEditor} />
            </Form.Item>
          </TabPane>
          <TabPane tab={<IntlMessages id="tour.inclusion" />} key="3">
            <Form.Item
              label={<IntlMessages id="tour.inclusion" />}
              name="inclusion"
              rules={[
                {
                  required: false,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
              valuePropName="data"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                return data;
              }}
            >
              <CKEditor editor={ClassicEditor} />
            </Form.Item>
          </TabPane>
          <TabPane tab={<IntlMessages id="tour.exclusion" />} key="4">
            <Form.Item
              label={<IntlMessages id="tour.exclusion" />}
              name="exclusion"
              rules={[
                {
                  required: false,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
              valuePropName="data"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                return data;
              }}
            >
              <CKEditor editor={ClassicEditor} />
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
