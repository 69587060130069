import Home from './home';
import Profile from './auth/Profile';
import Orders from './orders';
import Revenue from './reports/Revenue';
import Service from './reports/Service';
import Categories from './categories';
import Operators from './Operators';
import Tours from './tours';
import Countries from './countries';
import Destinations from './destinations';
import Currencies from './currencies';
import Posts from './Post';
import Sites from './sites';
import Customers from './customers';
import Users from './users';
import Wishlist from './wishlist';
import Widgets from './widgets';
import Proprotytypes from './propertytypes';

export const routes = [
    {
        path: 'home',
        component: Home,
        permission: null
    },



    {
        path: 'profile',
        component: Profile,
        permission: null
    },

    {
        path: 'orders',
        component: Orders,
        permission: null
    },
    {
        path: 'countries',
        component: Countries,
        permission: null
    },


    {
        path: 'reports/service',
        component: Service,
        permission: null
    },
    {
        path: 'reports/revenue',
        component: Revenue,
        permission: null
    },
    {
        path: 'customers',
        component: Customers,
        permission: null
    },
    {
        path: 'users',
        component: Users,
        permission: null
    },
    // {
    //     path: 'propertytypes',
    //     component: Proprotytypes,
    //     permission: null
    // },
    {
        path: 'categories',
        component: Categories,
        permission: null
    },
    {
        path: 'operators',
        component: Operators,
        permission: null
    },
    {
        path: 'tours',
        component: Tours,
        permission: null
    },
    {
        path: 'wishlist',
        component: Wishlist,
        permission: null
    },
    {
        path: 'destinations',
        component: Destinations,
        permission: null
    },

    // {
    //     path: 'options',
    //     component: Options,
    //     permission: null
    // },
    {
        path: 'currencies',
        component: Currencies,
        permission: null
    },
    {
        path: 'posts',
        component: Posts,
        permission: null
    },
    {
        path: 'sites',
        component: Sites,
        permission: null
    },

    {
        path: 'widgets',
        component: Widgets,
        permission: null
    },
    // {
    //     path: 'reports/room_performance',
    //     component: PerformanceRoom,
    //     permission: null
    // },



];


