import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Tabs,
} from "antd";
import IntlMessages from "../../components/IntlMessage";
import { useIntl } from "react-intl";
import currenciesApi from "../../api/currencies";
import UploadImage from "../../components/Elements/UploadImage";
import config from "../../config";
const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function CurrenciesForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    return () => {
      isMount.current = false;
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };
        delete data.image;
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          console.log("datakey", data[key]);
          formData.append(key, data[key]);
        });
        if (imgs) {
          formData.append("image", imgs);
        }
        console.log("vvv", formData);

        if (currentData) {
          currenciesApi
            .update(currentData.id, formData)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch(() => setLoading(false));
        } else {
          currenciesApi
            .create(formData)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch(() => setLoading(false));
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    onClose();
  };
  const currency = [
    { name: "ARS", symbol: "$" },
    { name: "AUD", symbol: "$" },
    { name: "BRL", symbol: "R$" },
    { name: "CAD", symbol: "$" },
    { name: "CHF", symbol: "CHF" },
    { name: "COP", symbol: "$" },
    { name: "DKK", symbol: "kr." },
    { name: "EUR", symbol: "€" },
    { name: "GBP", symbol: "£" },
    { name: "HKD", symbol: "HK$" },
    { name: "INR", symbol: "₹" },
    { name: "JPY", symbol: "¥" },
    { name: "MXN", symbol: "$" },
    { name: "NOK", symbol: "kr" },
    { name: "NZD", symbol: "$" },
    { name: "PEN", symbol: "S/" },
    { name: "SEK", symbol: "kr" },
    { name: "SGD", symbol: "$" },
    { name: "TWD", symbol: "NT$" },
    { name: "USD", symbol: "$" },
    { name: "ZAR", symbol: "R" },
  ];
  const currency1 = {
    ARS: "$",
    AUD: "$",
    BRL: "R$",
    CAD: "$",
    CHF: "CHF",
    COP: "$",
    DKK: "kr.",
    EUR: "€",
    GBP: "£",
    HKD: "HK$",
    INR: "₹",
    JPY: "¥",
    MXN: "$",
    NOK: "kr",
    NZD: "$",
    PEN: "S/",
    SEK: "kr",
    SGD: "$",
    TWD: "NT$",
    USD: "$",
    ZAR: "R",
  };

  // const [symbol,setSymbol] = useState("")
  // const handleChangeCurrency =(value) =>{
  //   currency.map((data)=>{
  //     if(data.name === value) setSymbol(data.symbol)
  //   })
  // }

  let initialValue = {
    name: currentData && currentData.name ? currentData.name : "",
    title: currentData && currentData.title ? currentData.title : "",
    symbol: currentData && currentData.symbol ? currentData.symbol : "",
    display: currentData && currentData.display ? currentData.display : "",
    image: currentData && currentData.image ? currentData.image : "",
    exchange_rate:
      currentData && currentData.exchange_rate ? currentData.exchange_rate : "",
    thousand: currentData && currentData.thousand ? currentData.thousand : "",
  };
  const fileList = useMemo(
    () =>
      currentData && currentData.image
        ? [
          {
            uid: "1",
            name: "image.png",
            status: "done ",
            url: `${config.MEDIA_URL}${currentData.image}`,
          },
        ]
        : [],
    [currentData]
  );
  const [imgs, setImgs] = useState(null);

  const onChangeImage = (v, f) => {
    setImgs(f);
  };

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        onValuesChange={(a, b) =>
          a.name ? form.setFieldsValue({ symbol: currency1[a.name] }) : null
        }
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.currencies" />} key="1">
            <Form.Item
              label={<IntlMessages id="currency.name" />}
              name="name"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                showSearch
                style={{
                  width: 120,
                }}
                //onChange = {handleChangeCurrency}
                placeholder="Select a currency"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {currency.map((option, index) => {
                  return (
                    <Option key={`${option.name}_${index}`} value={option.name}>
                      {option.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="currency.symbol" />}
              name="symbol"
            >
              <Input
                disabled
                style={{
                  width: 120,
                }}
              ></Input>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="currency.display" />}
              name="display"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="currency.exchange_rate" />}
              name="exchange_rate"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="currency.thousand" />}
              name="thousand"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="currency.image" />}
              name="image"
            >
              <UploadImage onChangeData={onChangeImage} initList={fileList} />
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
