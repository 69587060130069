
import api from '../utils/api';

class WidgetsApi {
  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get("/widgets", { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };



  create = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .post("/widgets", data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  update = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .patch(`/widgets/${id}`, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  delete = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .delete(`/widgets/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getDetail = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api
          .get(`/widgets/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
}
const widgetsApi = new WidgetsApi();
export default widgetsApi;