import React, { Component } from 'react';
import IntlMessages from "../components/IntlMessage"

import {
    AppstoreOutlined,
    DollarCircleOutlined,
    MessageOutlined,
    HomeOutlined,
    CalendarOutlined,
    PercentageOutlined,
    ReconciliationOutlined,
    UserOutlined,
    SettingOutlined,
    LineChartOutlined,
    UnorderedListOutlined,
    PayCircleOutlined,
    CarOutlined,
    CopyrightCircleOutlined,
    CopyrightOutlined,
    BranchesOutlined,
    EnvironmentOutlined,
    StarOutlined,
    GlobalOutlined,
    FileDoneOutlined

} from '@ant-design/icons';

const menu = [
    {

        path: '/app/orders',
        name: <IntlMessages id="global.order" />,
        icon: <CarOutlined />,
        permission: null,
        children: null
    },
    {
        path: '/app/customers',
        name: <IntlMessages id="global.customers" />,
        icon: <SettingOutlined />,
        permission: null,
        children: null
    },
    {
        path: '/app/users',
        name: <IntlMessages id="global.users" />,
        icon: <SettingOutlined />,
        permission: null,
        children: null
    },
    {
        path: '/app/countries',
        name: <IntlMessages id="global.countries" />,
        icon: <CopyrightOutlined />,
        permission: null,
        children: null
    },
    {
        path: '/app/destinations',
        name: <IntlMessages id="global.destination" />,
        icon: <EnvironmentOutlined />,
        permission: null,
        children: null
    },


    {
        path: '/app/categories',
        name: <IntlMessages id="global.categories" />,
        icon: <StarOutlined />,
        permission: null,
        children: null
    },
    {
        path: '/app/tours',
        name: <IntlMessages id="global.tour" />,
        icon: <GlobalOutlined />,
        permission: null,
        children: null
    },
    {
        path: '/app/wishlist',
        name: <IntlMessages id="global.wishlist" />,
        icon: <GlobalOutlined />,
        permission: null,
        children: null
    },
    {
        path: '/app/operators',
        name: <IntlMessages id="global.operators" />,
        icon: <SettingOutlined />,
        permission: null,
        children: null
    },
    // {
    //     path: '/app/options',
    //     name: <IntlMessages id="global.option" />,
    //     icon: <DollarCircleOutlined />,
    //     permission: null,
    //     children: null
    // },
    {
        path: '/app/currencies',
        name: <IntlMessages id="global.currencies" />,
        icon: <DollarCircleOutlined />,
        permission: null,
        children: null
    },

    {
        path: '/app/posts',
        name: <IntlMessages id="global.posts" />,
        icon: <FileDoneOutlined />,
        permission: null,
        children: null
    },

    {
        path: '/app/sites',
        name: <IntlMessages id="global.sites" />,
        icon: <FileDoneOutlined />,
        permission: null,
        children: null
    },

    {
        path: '/app/widgets',
        name: <IntlMessages id="global.widgets" />,
        icon: <FileDoneOutlined />,
        permission: null,
        children: null
    },


    // {
    //     path: '/app/reports',
    //     name: <IntlMessages id="sidebar.reports" />,
    //     icon: <LineChartOutlined />,
    //     permission: null,
    //     children: [
    //         // {
    //         //     path: '/app/reports/overview',
    //         //     name: "Tổng quát",
    //         //     permission: null,
    //         // },
    //         {
    //             path: '/app/reports/service',
    //             name: <IntlMessages id="sidebar.services" />,
    //             permission: null
    //         },

    //         {
    //             path: '/app/reports/revenue',
    //             name: <IntlMessages id="sidebar.earning" />,
    //             permission: null
    //         },

    //         // {
    //         //     path: '/app/reports/room_performance',
    //         //     name: "Công suất bán phòng",
    //         //     permission: null
    //         // },
    //     ]
    // },

];

export default menu;