import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  DatePicker,
  Button,
  Select,
  Tabs,
  Col,
  Row,
  Checkbox,
} from "antd";

import IntlMessages from "../../components/IntlMessage";
import { useIntl } from "react-intl";
import schedulesApi from "../../api/schedules";

import tourApi from "../../api/tour";

import moment from "moment";
const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function ScheduleForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open, dataTour } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const [option, setOption] = useState([]);
  const inputRef = useRef(null);

  const days = [
    {
      value: "1",
      label: "Sun",
    },
    {
      value: "2",
      label: "Mon",
    },
    {
      value: "3",
      label: "Tue",
    },
    {
      value: "4",
      label: "Wed",
    },
    {
      value: "5",
      label: "Thu",
    },
    {
      value: "6",
      label: "Fri",
    },
    {
      value: "7",
      label: "Sat",
    },
  ];

  useEffect(() => {
    isMount.current = true;
    async function fetchData() {
      if (isMount) {
        var filter = {
          page: 1,
          limit: 1000,
          keyword: "",
          order_by: "id",
          order_dir: "DESC",
        };
        let tour = await tourApi.getDetail(dataTour.id);
        setOption(tour.options);
      }
    }
    fetchData();
    return () => {
      isMount.current = false;
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    // console.log("option", form.getFieldValue("option_id"))

    form
      .validateFields()
      .then((values) => {
        let data = { ...values };
        data.start_date = moment(form.getFieldValue("start_date")).format(
          "YYYY-MM-DD"
        );
        data.end_date = moment(form.getFieldValue("end_date")).format(
          "YYYY-MM-DD"
        );
        // console.log("dataaaaa", data);
        if (currentData) {
          schedulesApi
            .update(currentData.id, data)
            .then((response) => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              Modal.error({
                title: error.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        } else {
          schedulesApi
            .create(data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };

  // console.log("Curren", currentData);

  let initialValue = {
    adult_price:
      currentData && currentData.adult_price ? currentData.adult_price : null,
    child_price:
      currentData && currentData.child_price ? currentData.child_price : null,
    weekdays: currentData
      ? currentData.weekdays
      : ["1", "2", "3", "4", "5", "6", "7"],
    start_date:
      currentData && currentData.start_date
        ? moment(currentData.start_date)
        : moment(),
    end_date:
      currentData && currentData.end_date ? moment(currentData.end_date) : null,
    option_id:
      currentData && currentData.option && currentData.option.id
        ? currentData.option.id
        : null,
  };

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.schedule" />} key="1">
            <Form.Item
              name="option_id"
              label={<IntlMessages id="global.option" />}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select placeholder="Select a option" optionFilterProp="children">
                {option.map((option, index) => {
                  return (
                    <Option key={`${option.id}_${index}`} value={option.id}>
                      {option.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.start_date" />}
              name="start_date"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                disabledDate={(current) =>
                  moment().add(-1, "days") >= current &&
                  current.valueOf() < Date.now()
                }
              />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.end_date" />}
              name="end_date"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                disabledDate={(current) =>
                  moment().add(-1, "days") >= current &&
                  current.valueOf() < Date.now()
                }
              />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.adult_price" />}
              name="adult_price"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.child_price" />}
              name="child_price"
            >
              <Input readOnly={dataTour.group} />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="global.weekdays" />}
              name="weekdays"
            >
              <Checkbox.Group>
                <Row>
                  {days.map((item, index) => {
                    return (
                      <Col key={index}>
                        <Checkbox value={item.value}>{item.label}</Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
