import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';

import menu from '../menu';
import MenuGroupItem from '../components/Menu/MenuGroupItem';
import MenuItem from '../components/Menu/MenuItem';


class AppSidebar extends Component {
    state = {
        collapsed: false
    }

    onCollapse = collapsed => {
        this.setState({ collapsed });
    };

    onClickMenu(key) {
        this.props.history.push(key)
    }

    render() {
        var { pathname } = this.props.location;
        var { collapsed } = this.state;

        return (

            <Menu
                theme="dark"
                mode="inline"
                selectedKeys={[pathname]}
                style={{ borderRight: 0 }}
                onClick={({ item, key, keyPath }) => this.onClickMenu(key)}
            >
                {
                    menu.map((item, key) => {
                        if (item.children) {
                            return <MenuGroupItem item={item} key={key} />
                        } else {
                            return <MenuItem item={item} key={item.path} />
                        }
                    })
                }
            </Menu>

        )
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser,
        config: state.config
    }
}

export default withRouter(connect(mapStateToProps)(AppSidebar));