import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Button } from 'antd';
import patientApi from '../../api/patient';
import { filter } from 'lodash-es';
import { Link } from 'react-router-dom';

import IntlMessages from "../../components/IntlMessage";


DrawerPatient.propTypes = {

};

function DrawerPatient(props) {
    const { onClose, showDrawer, visible, item } = props;
    const [patients, setPatients] = useState([]);
    const filter = {
        page: 1,
        limit: 10,
        keyword: item,
        order_by: 'id',
        order_dir: 'DESC',
    }
    // console.log(item);
    //  console.log(filter);

    useEffect(() => {

    }, [item])
    const getPatient = async () => {

    }

    return (
        <div>
            <Drawer
                width='20rem'
                title={<IntlMessages id="global.patient" />}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                {/* <div>
                    {patients.map((item, key) => {
                        
                        return (
                            <p key={item.id}>
                                <Link to={`/app/patient/${item.id}`} style={{ color: "blue" }}>
                                    {item.fullname}
                                </Link>
                            </p>
                        )
                    })}
                </div> */}
            </Drawer>
        </div>
    );
}

export default DrawerPatient;