import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  DatePicker,
  Button,
  Radio,
  Switch,
  Select,
  Tabs,
  InputNumber,
  Space,
  Divider,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import IntlMessages from "../../components/IntlMessage";
import { useIntl } from "react-intl";
import optionsApi from "../../api/options";
import tourApi from "../../api/tour";
import carApi from "../../api/cars";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export default function OptionForm(props) {
  const isMount = useRef(false);
  const { onClose, currentData, open, dataTour } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const [tour, setTour] = useState([]);
  const [cars, setCars] = useState([]);
  const [items, setItems] = useState(["8:00", "9:00"]);
  const [item, setItem] = useState("");
  const inputRef = useRef(null);
  console.log("dataTour", dataTour);

  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, item]);
    setItem("");
    setTimeout(() => {
      inputRef.current.focus();
    }, 0);
  };

  useEffect(() => {
    isMount.current = true;
    async function fetchData() {
      if (isMount) {
        var filter = {
          page: 1,
          limit: 1000,
          keyword: "",
          order_by: "id",
          order_dir: "DESC",
        };
        let tours = await tourApi.getAll(filter);
        let car = await carApi.getAll(filter);
        setTour(tours.data);
        setCars(car.data);
      }
    }
    fetchData();
    return () => {
      isMount.current = false;
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        if (currentData) {
          let data = { ...values };
          optionsApi
            .update(currentData.id, data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        } else {
          let data = { ...values, tour_id: dataTour.id };
          optionsApi
            .create(data)
            .then(() => {
              setLoading(false);
              handleCancel();
              props.onReload();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: "global.close" }),
              });
              setLoading(false);
            });
        }
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    //form.resetFields();
    onClose();
  };

  const [componentDisabled, setComponentDisabled] = useState(dataTour.group);

  let initialValue = {
    title: currentData && currentData.title ? currentData.title : "",
    description:
      currentData && currentData.description ? currentData.description : "",
    description:
      currentData && currentData.description ? currentData.description : "",
    car_id:
      currentData && currentData.car && currentData.car.id
        ? currentData.car.id
        : null,
    max_person:
      currentData && currentData.max_person ? currentData.max_person : "",
    adult_price:
      currentData && currentData.adult_price ? currentData.adult_price : "",
    child_price:
      currentData && currentData.child_price ? currentData.child_price : "",
    time_start: currentData && currentData.time_start ? currentData.time_start : [],
  };

  return (
    <Modal
      title={
        currentData ? (
          <IntlMessages id="global.edit" />
        ) : (
          <IntlMessages id="global.add" />
        )
      }
      visible={open}
      destroyOnClose={true}
      onCancel={handleCancel}
      width="60%"
      onOk={handleSubmit}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          {<IntlMessages id="global.cancel" />}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="global.save" />}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        labelAlign="left"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={<IntlMessages id="global.option" />} key="1">
            <Form.Item
              label={<IntlMessages id="global.title" />}
              name="title"
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.timeStart" />}
              name="time_start"
              rules={[
                {
                  required: false,
                  //message: <IntlMessages id="global.requiredfield" />,
                },
              ]}
            >
              <Select
                placeholder="Start at..."
                mode="multiple"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <Input
                        placeholder="Please enter item"
                        ref={inputRef}
                        value={item}
                        onChange={(e) => setItem(e.target.value)}
                      />
                      <Button
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={addItem}
                      >
                        Add item
                      </Button>
                    </Space>
                  </>
                )}
              >
                {items.map((item) => (
                  <Option key={item}>{item}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="car_id"
              label={<IntlMessages id="global.Vehicles" />}
            >
              <Select
                showSearch
                disabled={!componentDisabled}
                placeholder="Select a Vehicle "
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {cars.map((option, index) => {
                  return (
                    <Option key={`${option.id}_${index}`} value={option.id}>
                      {option.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={<IntlMessages id="global.max_person" />}
              name="max_person"
            >
              <Input />
            </Form.Item>


          </TabPane>
          <TabPane tab={<IntlMessages id="global.description" />} key="2">
            <Form.Item
              label={<IntlMessages id="global.description" />}
              name="description"
              rules={[
                {
                  required: false,
                },
              ]}
              valuePropName="data"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                return data;
              }}
            >
              <CKEditor editor={ClassicEditor} />
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}
