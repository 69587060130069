import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import IntlMessages from "../../components/IntlMessage";
import { Button, Card, Table, Tag, Modal, Switch, Row, Col, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PageTitle from "../../components/PageTitle";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import ActionBar from "../../components/ActionBar";
import SitesForm from "./SitesForm";
import sitesApi from "../../api/sites";
import store from "../../redux/store";

Sites.propTypes = {
  items: PropTypes.array,
};
Sites.defaultProps = {
  items: [],
};

function Sites(props) {
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [item, setItem] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    order_by: "email",
    order_dir: "ASC",
  });
  const [modal, setModal] = useState(false);

  const onChangeTable = async (
    pagination,
    filters,
    sorter,
    extra = { currentDataSource: [] }
  ) => {
    setFilter({
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order_dir: getOrder(sorter.order),
      order_by: sorter.columnKey ? `${sorter.columnKey}` : "title",
    });
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let items = await sitesApi.getAll(filter);

      setItems(items.data);
      setLoading(false);
      setDeleting(false);
      if (total != items.total) setTotal(items.total);
    }
    fetchData();
  }, [filter, deleting, reload]);

  const onReload = () => {
    setReload((reload) => !reload);
  };

  const onOpenModal = (record = null) => {
    setModal(true);
    setItem(record);
    console.log(record);
  };

  const onCloseModal = () => {
    setModal(false);
  };
  const intl = useIntl();
  const onRemove = (data) => {
    Modal.confirm({
      title: intl.formatMessage({ id: "global.delete_confirm" }),
      okText: intl.formatMessage({ id: "global.yes" }),
      onOk: async () => {
        await sitesApi.delete(data.id);
        setDeleting(true);
      },
      onCancel() {},
    });
  };
  const onChangeStatus = async (ischecked, record) => {
    await sitesApi.update(record.id, { status: ischecked });

    setItems((items) =>
      items.map((data) => {
        if (data.id === record.id)
          return {
            ...data,
            status: ischecked,
          };
        return data;
      })
    );
  };

  const getOrder = (order) => {
    if (order === "ascend") return "ASC";
    if (order === "descend") return "DESC";
    return "ASC";
  };

  const columns = [
    {
      title: <IntlMessages id="global.id_number" />,
      key: "id",
      width: 20,
      sorter: true,
      dataIndex: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: <IntlMessages id="sites.email" />,
      key: "email",
      sorter: true,

      dataIndex: "email",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => onOpenModal(record)}
            shape="round"
            icon={<EditOutlined />}
          >
            {record.email}
          </Button>
        );
      },
    },
    {
      title: <IntlMessages id="global.name" />,
      key: "name",
      sorter: true,

      dataIndex: "name",
      render: (text, record) => {
        return `${record.name}`;
      },
    },

    {
      title: <IntlMessages id="sites.currency" />,
      key: "currency",
      sorter: true,

      dataIndex: "currency",
      render: (text, record) => {
        return `${record.currency}`;
      },
    },
    {
      title: <IntlMessages id="sites.locale" />,
      key: "locale",
      sorter: true,

      dataIndex: "locale",
      render: (text, record) => {
        return (
          //<IntlMessages id={`group.${record.description}`} />;
          `${record.locale}`
        );
      },
    },
    {
      title: <IntlMessages id="sites.hotline" />,
      key: "hotline",
      sorter: true,

      dataIndex: "hotline",
      render: (text, record) => {
        return `${record.hotline}`;
      },
    },

    {
      title: <IntlMessages id="global.status" />,
      dataIndex: "status",
      width: 100,
      key: "status",
      render: (e, record) =>
        record ? (
          <Switch
            checkedChildren={""}
            unCheckedChildren={""}
            checked={!!e}
            onChange={(ischecked) => onChangeStatus(ischecked, record)}
          />
        ) : null,
    },

    {
      title: "",
      width: 100,
      align: true,
      render: (text, record) => {
        return (
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => onRemove(record)}
              danger
              shape="round"
              icon={<DeleteOutlined />}
            ></Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Card bordered={false} style={{ minHeight: "100%" }}>
      <Row justify="space-between">
        <Col>
          {" "}
          <PageTitle title={<IntlMessages id="global.operators" />} />
        </Col>

        <Col>
          <ActionBar
            isShowAddButton={true}
            onAdd={onOpenModal}
            onRefresh = {()=>onReload()}
            isShowPublishButtons={false}
            isShowCopyButton={false}
            isShowDeleteButton={false}
          ></ActionBar>
        </Col>
      </Row>

      <Table
        tableLayout="auto"
        columns={columns}
        dataSource={items}
        onChange={onChangeTable}
        rowKey="id"
        size="small"
        loading={loading}
        scroll={{
          x: "max-content",
        }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"],
          total: total,
          current: filter.page,
          pageSize: filter.limit,
        }}
      />
      {modal ? (
        <SitesForm
          open={modal}
          onClose={onCloseModal}
          currentData={item}
          onReload={onReload}
        />
      ) : null}
    </Card>
  );
}

export default Sites;
